export const CONFIGS = {
	button: {
		types: {
			Negative: "Negative",
			Transparent: "Transparent",
			Light: "Light",
			Dark: "Dark",
		},
	},

	splash: {
		types: {
			Loading: "Loading",
			Error: "Error",
			Success: "Success",
			Alert: "Alert",
			Sync: "Sync",
		},
		timer: {
			timeout: 1500,
			fadeIn: 500,
			fadeOut: 350,
			ad: 5000,
		},
	},

	languages: [
		{ name: "English (US)", code: "en" },
		{ name: "Português (BR)", code: "br" },
		{ name: "العربية", code: "ar", dir: "rtl" },
	],

	openWeatherToken: "745ed08bf8e5326f1f7f8f8c99c522a0",
	agroAPIToken: "745ed08bf8e5326f1f7f8f8c99c522a0",
	agroAPIPeriod: 3,
	mapboxToken:
		"pk.eyJ1IjoiYW51YnoiLCJhIjoiY2t6NG5vdm00MGlqcTJvbng3bjhqZnRuMiJ9.sjPB8w38on_djZ8XvRp_aA",

	urlApiLocal: "http://localhost",
	urlApiLocalMap: "http://localhost:3000",
	urlApiProduction: "https://sid.anubz.io",

	// apiLabels: {
	//   apiGeocode: "apiGeocode",
	//   apiSatellite: "apiSatellite",
	//   apiWeather: "apiWeather",
	//   apiSoil: "apiSoil",
	//   apiUV: "apiUV",
	//   apiCAR: "apiCAR",
	//   apiStats: "apiStats",
	//   apiPrecipitation: "apiPrecipitation",
	//   apiFire: "apiFire",
	// },

	apiPermissions: {
		apiWeather: { code: "apiWeather", title: "Clima" },
		apiSoil: { code: "apiSoil", title: "Solo" },
		apiCarbon: { code: "apiCarbon", title: "Carbono" },
		apiCompliance: { code: "apiCompliance", title: "Compliance" },
		apiSatellite: { code: "apiSatellite", title: "Satélite" },
		apiBiodiversity: { code: "apiBiodiversity", title: "Biodiversidade" },
		apiFire: { code: "apiFire", title: "Fogo" },
		apiInspections: { code: "apiInspections", title: "Inspeções" },
		apiStats: { code: "apiStats", title: "Analytics" },
		apiPrecipitation: { code: "apiPrecipitation", title: "Precipitação" },
		api3DBuilding: { code: "api3DBuilding", title: "Edifícios 3D" },
		apiDesmatamento: { code: "apiDesmatamento", title: "Desmatamento" },

		// apiGeocode: { code: "apiGeocode", title: "Geolocalização" },
		// apiUV: { code: "apiUV", title: "Índice UV" },
		// apiCAR: { code: "apiCAR", title: "CAR" },
	},

	nasaFireAPI: {
		tokenList: [
			"1dae9bb85d2a614cf1831690b995770f",
			"ce9f42a932c708ed9061bb896ad04962",
			"873f455695b2fce12aaf543ebf91aa71",
			"e8ff60bac3bf51d4b8ff7ade0defe1df",
			"5d62c4af3246fb8302686ff57cd886da",
			"6ccf8b280527d9431911f4d20ddb1859",
			"2d14d458cd4e4d57f48d0ac782c13724",
			"5609b82015b2d632dd5ca88f49fd6e04",
		],
		buffer: 5,
		servicesList: [
			"MODIS_NRT",
			"MODIS_SP",
			"VIIRS_NOAA20_NRT",
			"VIIRS_NOAA21_NRT",
			"VIIRS_SNPP_NRT",
			"VIIRS_SNPP_SP",
		],
	},

	mapBiomas: {
		endpoint: "https://plataforma.alerta.mapbiomas.org/api/v2/graphql",
		email: "rodolfo@anu.bz",
		password: "1qweDSA@@MAPBIOMAS",
	},
};
