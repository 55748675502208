import React, { useState, useEffect } from "react";

import ReactSlider from "react-slider";
import "../slider/slider.css";

import { useDataMap } from "../../contexts/datamap";

const Slider = () => {
	const dataMapContext = useDataMap();
	const [currentValue, setCurrentValue] = useState(0.25);

	// useEffect(() => {
	//   console.log("currentValue ", dataMapContext.rasterLayerOpacity);
	// }, [dataMapContext.rasterLayerOpacity]);

	return (
		<>
			<div className="customSliderContainer">
				<ReactSlider
					className="customSlider"
					thumbClassName="customSlider-thumb"
					trackClassName="customSlider-track"
					//   markClassName="customSlider-mark"
					//   marks={20}
					min={0}
					max={100}
					defaultValue={currentValue * 100}
					value={dataMapContext.rasterLayerOpacity * 100}
					onChange={(value) => {
						let valueDecimal = value / 100;

						dataMapContext.setRasterLayerOpacity(valueDecimal);
						setCurrentValue(valueDecimal);
					}}
					renderMark={(props) => {
						if (props.key < currentValue) {
							props.className = "customSlider-mark customSlider-mark-before";
						} else if (props.key === currentValue) {
							props.className = "customSlider-mark customSlider-mark-active";
						}
						return <span {...props} />;
					}}
				/>
			</div>
		</>
	);
};

export default Slider;
