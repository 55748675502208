import { createGlobalStyle } from "styled-components";
import { COLORS } from "../colors";
import { DEVICES } from "../devices";

const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    
    font-size: 1em;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased !important;
  }

  body {
    background: ${COLORS.gray_superlight};
    transition: all 0.2s ease-in-out;
  }

  html, body {
    min-height: 100vh;
  }

  p {
    font-weight: 300;
  }

  a, a:visited {
    color: ${COLORS.gray_light};
    text-decoration: none;
  }
  
  a:hover {
    transition: 0.5s ease-in-out;
    text-decoration: underline;
  }

  #root,
  .mapboxgl-canvas, 
  .mapboxgl-map {
    width: 100vw !important;
    height: 100vh !important;
  }

  @media ${DEVICES.maxTablet} {
    .mapboxgl-popup {
      max-width: 14em !important;
    }
  }
  
  @keyframes rotate {
    to { transform: rotate(1turn); }
  }
  
  .rotate {
    animation: rotate .75s linear infinite; 
  }
  
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker { 
    margin-top: 1em;
  }

`;

export default GlobalStyle;
