import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import * as turf from "@turf/turf";
import { DateTime } from "luxon";

import { CONFIGS } from "../../../assets/configs";
import { convertLongToShort } from "../../../services/converters";
import { useDataMap } from "../../../contexts/datamap";

import Loading from "./loading";

export default function Fire() {
	const { t } = useTranslation();

	const dataMapContext = useDataMap();
	const nasaFireServiceList = CONFIGS.nasaFireAPI.servicesList;

	const [fireData, setFireData] = useState(null);
	const [timerTime, setTimerTime] = useState(0);

	/////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (dataMapContext.dataLotFire?.features) {
			let dataFire = dataMapContext.dataLotFire.features.filter((feature) => {
				return feature.properties.buffer !== true;
			});
			setFireData(dataFire);
		}
		// eslint-disable-next-line
	}, [dataMapContext.dataLotFire]);

	useEffect(() => {
		setTimerTime(dataMapContext.timerTime);
	}, [dataMapContext.timerTime]);

	/////////////////////////////////////////////////////////////////

	useEffect(() => {
		dataMapContext.setNotificationData({
			...dataMapContext.notificationData,
			apiFire: false,
		});
	}, []);

	/////////////////////////////////////////////////////////////////

	return (
		<>
			{fireData ? (
				<>
					<h1>
						{t("map.menu.apiFire.title")} ({fireData.length})
					</h1>

					<h2>
						{t("map.menu.apiFire.description")}

						{/* :&nbsp;
						{nasaFireServiceList.map((service, index) => {
							return <span key={index}>{service}, </span>;
						})} */}
					</h2>

					{fireData.length === 0 ?? (
						<>
							<p>{t("map.menu.apiFire.detectedNo")}</p>
						</>
					)}

					<ul>
						{fireData.map((fire, index) => {
							let dayNight =
								fire.properties.daynight === "D"
									? t("map.menu.apiFire.detectedDay")
									: t("map.menu.apiFire.detectedNight");

							return (
								<li key={index}>
									{/* {convertLongToShort(fire.geometry.coordinates[1], 4)},{" "} */}
									{/* {convertLongToShort(fire.geometry.coordinates[0], 4)},{" "} */}
									{/* ({dayNight}) */}
									<span>
										<strong>{t("map.menu.apiFire.date")}</strong>:{" "}
										{DateTime.fromISO(fire.properties.acq_date).toLocaleString()},{" "}
										{fire.properties.acq_time}
									</span>
									<br />

									<span>
										<strong>{t("map.menu.apiFire.firePower")}</strong>:{" "}
										{fire.properties.frp}
									</span>
									<br />

									<span>
										<strong>Network</strong>: {fire.properties.network}
									</span>
								</li>
							);
						})}
					</ul>

					<p>
						Time to refresh: {`${Math.floor(timerTime / 60)}`.padStart(2, 0)}:
						{`${timerTime % 60}`.padStart(2, 0)}
					</p>
				</>
			) : (
				<Loading />
			)}
		</>
	);
}
