import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useErrorHandler, ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../errorfallback";

import { COLORS } from "../../assets/colors";
import { CONFIGS } from "../../assets/configs";
//
import { MapAdMini } from "../../assets/styles/map";
import Button from "../button";

export default function AdMini(props) {
	//
	const handleError = useErrorHandler();

	const { t } = useTranslation();
	const { ad, adOpen } = props;

	useEffect(() => {}, []);

	let adTitle = ad?.campaign ? ad.campaign : "";
	let adImage = ad?.picture ? (
		<img src={ad.picture} alt={adTitle} onClick={adOpen} />
	) : (
		""
	);

	if (ad?.url) {
		let adUrl = ad.url + "?utm_source=ForestWatch&utm_campaign=" + ad?.urlTitle;

		// adImage = (
		//   <a href={adUrl} title={adTitle}>
		//     {adImage}
		//   </a>
		// );
	}

	return (
		<MapAdMini style={{ backgroundColor: COLORS.backgroundContainers }}>
			{adImage}
		</MapAdMini>
	);
}
