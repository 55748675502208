import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CONFIGS } from "../assets/configs";
import { useSplash } from "../contexts/splash";

export default function HttpStatus() {
  //
  const routeParams = useParams();
  const currentRoute = useLocation().pathname;

  console.log(routeParams);

  const splashContext = useSplash();
  // const { t, i18n } = useTranslation();

  useEffect(() => {
    splashContext.closeSplash();
  }, []);

  /////////////////////////////////////////////////////////////////////
  // localization

  return (
    <>
      <h1>Error:{currentRoute}</h1>
      <br />
    </>
  );
}
