import styled from "styled-components";

import { COLORS } from "../colors";
import { DEVICES } from "../devices";

export const H1 = styled.h1`
	font-family: "Roboto", sans-serif;
	font-size: 2em;
	text-transform: uppercase;

	padding: 0;
	margin: 0;
`;

export const H2 = styled.h2`
	font-family: "Roboto", sans-serif;
	font-weight: 100;

	text-transform: uppercase;

	padding: 0;
	margin: 0 0 1.5em 0;
`;

export const H3 = styled.h3`
	font-family: "Roboto", sans-serif;
	font-weight: 500;

	padding: 0;
	margin: 0 0 1em 0;
`;

export const H4 = styled.h4`
	font-family: "Roboto", sans-serif;
	font-weight: 100;

	padding: 0;
	margin: 0;
	font-style: italic;
`;

export const P = styled.p`
	text-align: center;
	line-height: 130%;

	font-size: 1.125em;
	font-weight: 100;

	margin-bottom: 2em;
`;

export const HTMLButton = styled.button`
	justify-content: "center";
	align-content: "center";

	font-size: 1.5em;
	font-weight: 500;

	padding: 0.75em 3em;
	margin-top: 2em;

	border-radius: 8px;
	cursor: pointer;

	&:hover {
		background-position: right left;
	}

	&.bordered {
		border-style: solid;
		border-width: 1;
		border-color: ${COLORS.ice};
	}

	&.borderless {
		border-width: 0;
	}
`;

export const BUTTON = styled.button`
	justify-content: "center";
	align-content: "center";

	text-align: center;
	background: transparent;
	cursor: pointer;
	color: #444;

	padding: 0.75em 1.5em;
	margin: 1em 0 0 0;

	border: solid;
	border-radius: 10em;
	border-color: #444;
	border-width: 1px;

	font-family: "Roboto", sans-serif;
	font-weight: 400;
	text-transform: capitalize;
	/* letter-spacing: 0.05em; */

	&:disabled,
	[disabled] {
		border: 1px solid #dbdbdb;
		color: #dbdbdb;
	}
`;

export const IMAGE = styled.img`
	width: 100%;
	height: auto;

	border-radius: 0.5em;
	margin: 0;
`;

export const FORM = styled.form`
	width: 100%;
	margin: 0 auto;

	display: flex;
	flex-flow: column nowrap;
	flex-grow: 1;
	flex-shrink: 1;

	@media ${DEVICES.simpleTablet} {
	}
`;

export const INPUT = styled.input`
	font-family: "Roboto", sans-serif;
	font-weight: 300 !important;
	font-size: 1em;
	font-weight: 100;

	padding: 1em;
	margin-bottom: 0.75em;
	letter-spacing: 0.0125em;

	border: solid 1px;
	border-color: #999;
	border-radius: 0.625em;
	color: #333;

	&::placeholder {
		color: #a1a1a1;
	}

	&.invalid {
		border-color: red !important;
	}
`;
