import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "react-error-boundary";
import { useParams } from "react-router-dom";
import { GraphQLClient, gql } from "graphql-request";
import * as WKT from "wellknown";

import { CONFIGS } from "../../../assets/configs";
import { useDataMap } from "../../../contexts/datamap";
import { convertLocaleSeparator } from "../../../services/converters";

import Loading from "./loading";

export default function Deforestation() {
	const { t } = useTranslation();
	const calledOnce = useRef(false);

	const dataMapContext = useDataMap();
	const handleError = useErrorHandler();

	const [mapbiomasToken, setMapbiomasToken] = useState();

	const [mapbiomasCar, setMapbiomasCar] = useState();
	const [mapbiomasAlerts, setMapbiomasAlerts] = useState();
	const [mapbiomasAlertsResults, setMapbiomasAlertsResults] = useState(false);
	const [mapbiomasAlertsData, setMapbiomasAlertsData] = useState([]);

	const routeParams = useParams();
	const slugClient = routeParams.slugClient;
	const slugLot = routeParams.slugLot;

	const slugDeforestationStore = "@ForestWatch:Deforestation:" + slugLot;
	const slugDeforestationSaved = "@ForestWatch:Deforestation:saved";

	/////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (calledOnce.current) {
			return;
		}

		const sessionDeforestation = sessionStorage.getItem(slugDeforestationStore);

		let hours = 24;
		let saved = localStorage.getItem(slugDeforestationSaved);

		if (saved && new Date().getTime() - saved > hours * 60 * 60 * 1000) {
			localStorage.clear();
		}

		const callSignIn = async () => {
			await mapbiomasSignIn();
			mapbiomasPropertyAlerts();
		};

		callSignIn();

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (mapbiomasAlerts && !calledOnce.current) {
			mapbiomasAlertData();
			calledOnce.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapbiomasAlerts]);

	/////////////////////////////////////////////////////////////////

	async function mapbiomasSignIn() {
		const graphQLClient = new GraphQLClient(CONFIGS.mapBiomas.endpoint, {});

		const mutation = gql`
			mutation signIn($email: String!, $password: String!) {
				signIn(email: $email, password: $password) {
					token
				}
			}
		`;

		const variables = {
			email: CONFIGS.mapBiomas.email,
			password: CONFIGS.mapBiomas.password,
		};

		await graphQLClient
			.request(mutation, variables)
			.then((data) => {
				const tokenBearer = `Bearer ` + data.signIn.token;
				setMapbiomasToken(tokenBearer);
				return tokenBearer;
			})
			.catch((err) => {
				// handleError(err);
			});
	}

	async function mapbiomasPropertyAlerts() {
		const registers = dataMapContext.dataLotProperties.properties.registers;
		const registersCar = registers.filter((reg) => reg.type === "car");

		if (registersCar.length > 0) {
			const car = registersCar[0].value;
			setMapbiomasCar(car);

			const graphQLClient = new GraphQLClient(CONFIGS.mapBiomas.endpoint, {
				headers: {
					authorization: mapbiomasToken,
				},
			});

			const query = gql`
				query ruralProperty($carCode: String!) {
					ruralProperty(propertyCode: $carCode) {
						alerts {
							alertCode
							areaHa
							detectedAt
						}
						carUpdatedAt
					}
				}
			`;

			const variables = {
				carCode: car,
			};

			await graphQLClient
				.request(query, variables)
				.then((data) => {
					const alertsData = data.ruralProperty ? data.ruralProperty.alerts : [];
					setMapbiomasAlerts(alertsData);
					setMapbiomasAlertsResults(true);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}

	async function mapbiomasAlertData() {
		//

		const graphQLClient = new GraphQLClient(CONFIGS.mapBiomas.endpoint, {
			headers: {
				authorization: mapbiomasToken,
			},
		});

		const query = gql`
			query alert($alertCode: Int!, $carCode: String) {
				alert(alertCode: $alertCode, carCode: $carCode) {
					alertCode
					areaHa
					detectedAt
					publishedAt
					coordenates {
						latitude
						longitude
					}
					geometryWkt

					deforestationClasses
					sources

					# alertGeometry {
					#	 changes
					# }

					crossedBiomes
					crossedSpecialTerritories

					crossedBiosphereReserves
					crossedConservationUnits
					crossedDeforestationAuthorizationsActivities
					crossedDeforestationAuthorizationsCategories
					crossedDeforestationAuthorizationsList
					crossedEmbargoesRuralPropertiesList
					crossedFederalProtectedAreaIntegralProtections
					crossedFederalProtectedAreaSustainableUses
					crossedForestManagementsActivities
					crossedIndigenousLands
					crossedLegalReserveRuralPropertiesList
					crossedLegalReservesList
					crossedPermanentProtectedAreaList
					crossedQuilombos
					crossedSettlements
					crossedSettlementsRuralPropertiesList

					ruralPropertiesList

					imageAcquiredAfterList
					imageAcquiredBeboreList
					publishedImages {
						url
					}
				}
			}
		`;

		mapbiomasAlerts.map(async (alert) => {
			const variables = {
				alertCode: Number(alert.alertCode),
				carCode: mapbiomasCar,
			};

			await graphQLClient
				.request(query, variables)
				.then((data) => {
					const alertData = data.alert;

					setMapbiomasAlertsData((prevAlerts) => [...prevAlerts, alertData]);

					if (dataMapContext.deforestationData.length === 0) {
						const wktGeojson = WKT.parse(alertData.geometryWkt);
						const alertGeoJson = {
							type: "Feature",
							geometry: wktGeojson,
							properties: {
								polygon: true,
								type: "deforestation",
								...alertData,
							},
						};

						dataMapContext.setDeforestationData((prevPolygons) => [
							...prevPolygons,
							alertGeoJson,
						]);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		});
	}

	/////////////////////////////////////////////////////////////////

	if (!mapbiomasAlerts && !mapbiomasAlertsResults) return <Loading />;
	return (
		<>
			<h1>Total de Alertas: {convertLocaleSeparator(mapbiomasAlerts.length)}</h1>
			{mapbiomasAlerts.length > 0 ? (
				mapbiomasAlertsData.map((alert, index) => {
					return (
						<div key={index}>
							<h2>Código: {alert.alertCode}</h2>
							<p>Área: {convertLocaleSeparator(alert.areaHa)} ha</p>
							<p>Detectado em: {alert.detectedAt}</p>
							<p>Publicado em: {alert.publishedAt}</p>
							<br />
						</div>
					);
				})
			) : (
				<h2>Nenhum alerta de desmatamento</h2>
			)}
		</>
	);
}
