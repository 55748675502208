import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { useSplash } from '../../contexts/splash';
import { useDataMap } from '../../contexts/datamap';

import { MapStatus } from '../../assets/styles/map';
import { convertLocaleSeparator } from '../../services/converters';

export default function Status(props) {
  //
  const { t } = useTranslation();

  const splashContext = useSplash();
  const dataMapContext = useDataMap();

  const [totalPoints, setTotalPoints] = useState(null);
  const [mousePosition, setMousePosition] = useState(null);

  ///////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////

  useEffect(() => {
    if (dataMapContext.dataLotProperties?.properties.points) {
      setTotalPoints(dataMapContext.dataLotProperties.properties.points);
    }
  }, [dataMapContext.dataLotProperties]);

  useEffect(() => {
    if (dataMapContext.mousePositionLatLong) {
      setMousePosition(dataMapContext.mousePositionLatLong);
    }
  }, [dataMapContext.mousePositionLatLong]);

  ///////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////

  return (
    <MapStatus>
      {totalPoints && (
        <>Total de pontos: {convertLocaleSeparator(totalPoints)}</>
      )}

      {mousePosition && (
        <>
          <h3>
            lat: {mousePosition.lat}, lng: {mousePosition.lng}
          </h3>
        </>
      )}
    </MapStatus>
  );
}
