import React, { useEffect } from "react";
import { Popup } from "react-map-gl";
import { ImgComparisonSlider } from "@img-comparison-slider/react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { CONFIGS } from "../../assets/configs";
import { COLORS } from "../../assets/colors";
import { BUTTON } from "../../assets/styles/html";

export default function PopUp(props) {
	//
	const { t } = useTranslation();

	const { data, setShowPopup, openAd } = props;
	const isProfile = data.class !== "lot" && !data?.polygon ? true : false;

	const API_URL =
		process.env.NODE_ENV !== "development"
			? CONFIGS.urlApiProduction
			: CONFIGS.urlApiLocal;

	useEffect(() => {
		console.log("isProfile", isProfile);
	}, []);

	const openProfile = (url) => {
		window.open(
			// window.location.origin + "/" + url,
			API_URL + "/" + url,
			"_blank",
			"noopener,noreferrer"
		);
	};

	return (
		<Popup
			id={data?.id}
			longitude={data?.longitude}
			latitude={data?.latitude}
			closeButton={true}
			closeOnClick={false}
			className={data.class ? data.class : "item"}
			onClose={() => {
				setShowPopup(false);
			}}
			anchor="bottom"
			maxWidth="none"
			offset={[0, 0]}
			dynamicPosition={true}>
			{data.class !== "deforestation" ? (
				<>
					<img
						key={data?.title.toString()}
						src={data?.image}
						title={data?.title}
						alt={data?.description}
					/>
					<h1>{data?.title}</h1>
					<h2>
						{isProfile ? (
							<>
								<a
									href={API_URL + "/" + data?.subtitle}
									target="_blank"
									rel="noreferrer">
									# {data?.subtitle}
								</a>
							</>
						) : (
							<>{data?.subtitle}</>
						)}
					</h2>

					{data?.description !== null && <p>{data.description}</p>}

					{isProfile ? (
						<BUTTON
							style={{ backgroundColor: COLORS.gray_mid, color: COLORS.white }}
							onClick={() => {
								openProfile(data?.subtitle);
							}}>
							{t("buttons.openProfile")}
						</BUTTON>
					) : (
						<></>
					)}
				</>
			) : (
				<>
					<h2>Alert #{data?.id}</h2>{" "}
					<h3>
						{DateTime.fromISO(data?.date).toLocaleString(DateTime.DATETIME_MED)}
					</h3>
					<ImgComparisonSlider className="slider-relative-size">
						<figure slot="first" className="slider-image-satellite before">
							<img alt="Before" src={data?.images[0].url} />
							<figcaption>
								{data?.imageAcquiredBeforeList?.satellite},{" "}
								{data?.imageAcquiredBeforeList?.acquired_at},{" "}
								{data?.imageAcquiredBeforeList?.constellation}
							</figcaption>
						</figure>

						<figure slot="second" className="slider-image-satellite after">
							<img alt="After" src={data?.images[1].url} />
							<figcaption>
								{data?.imageAcquiredAfterList?.constellation}{" "}
								{data?.imageAcquiredAfterList?.satellite}
								<br />
								{DateTime.fromISO(
									data?.imageAcquiredAfterList?.acquired_at
								).toLocaleString(DateTime.DATETIME_MED)}
							</figcaption>
						</figure>
					</ImgComparisonSlider>
					<ul>
						<li>
							<strong>Área</strong> {data?.areaHa}ha
						</li>
						<li>
							<strong>Vetor</strong> {data?.deforestationClasses}
						</li>
						<li>
							<strong>Bioma</strong> {data?.crossedBiomes}
						</li>
						<li>
							<strong>Território</strong> {data?.crossedSpecialTerritories}
						</li>
						<li>
							<strong>Source</strong> {data?.source}
						</li>
						<li>
							<strong>Constellation</strong> {data?.constellation}
						</li>
					</ul>
				</>
			)}
		</Popup>
	);
}

export { PopUp };
