import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
	.use(Backend)
	.use(initReactI18next)
	.use(LanguageDetector)
	.init(
		{
			detection: {
				order: ["localStorage", "cookie"], //"path",
				caches: ["localStorage", "cookie"], // cache user language on
			},
			backend: {
				loadPath: "/locales/{{lng}}/{{ns}}.json",
			},
			react: {
				useSuspense: true,
			},
			fallbackLng: "en",
			debug: true,
		},

		(err, t) => {
			if (err) {
				console.log("error on i18n");
				return console.error(err);
			}

			console.log("i18n language", t("welcome", { lng: "br" }));
		}
	);

export default i18n;
