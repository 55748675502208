import axios from "axios";
import { CONFIGS } from "../assets/configs";

axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

// baseURL: "http://127.0.0.1:3333",
// baseURL: "http://localhost",
// baseURL: "https://sid.anubz.io",

export const API_URL =
	process.env.NODE_ENV !== "development"
		? CONFIGS.urlApiProduction
		: CONFIGS.urlApiLocal;

const api = axios.create({
	withCredentials: false,
	headers: {
		"Access-Control-Allow-Origin": "*",
		"Content-Type": "application/json",
	},
	mode: "no-cors",
	baseURL: API_URL,
});

export default api;
