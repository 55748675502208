import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Authed from "../pages/authed/authed";
import Dashboard from "../pages/authed/dashboard";

export default function AuthedRoutes() {
  let location = useLocation();

  return (
    <Routes location={location}>
      <Route path="/" element={<Dashboard />}></Route>
      <Route path="/authed" element={<Authed />}></Route>
    </Routes>
  );
}
