import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { load } from '@loaders.gl/core';
import { JSONLoader } from '@loaders.gl/json';
import { useErrorHandler } from 'react-error-boundary';

import { useParams } from 'react-router-dom';

import { CONFIGS } from '../../../assets/configs';
import { useDataMap } from '../../../contexts/datamap';
import Loading from './loading';
import { convertLocaleSeparator } from '../../../services/converters';

const API_URL =
  process.env.NODE_ENV !== 'development'
    ? CONFIGS.urlApiProduction
    : CONFIGS.urlApiLocal;

export default function Biodiversity() {
  const handleError = useErrorHandler();

  const { t } = useTranslation();

  const dataMapContext = useDataMap();
  const [dataBiodiversity, setDataBiodiversity] = useState();

  const routeParams = useParams();
  const slugClient = routeParams.slugClient;
  const slugLot = routeParams.slugLot;

  const slugBiodiversityStore = '@ForestWatch:Biodiversity:' + slugLot;
  const slugBiodiversitySaved = '@ForestWatch:Biodiversity:saved';

  /////////////////////////////////////////////////////////////////

  useEffect(() => {
    const sessionBiodiversity = sessionStorage.getItem(slugBiodiversityStore);

    let hours = 24;
    let saved = localStorage.getItem(slugBiodiversitySaved);

    // console.log(saved);
    // console.log(sessionBiodiversity);

    if (saved && new Date().getTime() - saved > hours * 60 * 60 * 1000) {
      localStorage.clear();
    }

    if (!sessionBiodiversity) {
      callApiBiodiversity();
    } else {
      setDataBiodiversity(JSON.parse(sessionBiodiversity));
    }
    // eslint-disable-next-line
  }, []);

  /////////////////////////////////////////////////////////////////

  async function callApiBiodiversity() {
    //
    let fetchURL =
      API_URL + '/api/v3/maps/' + slugClient + '/' + slugLot + '/biodiversity';

    await load(fetchURL, JSONLoader)
      .then((resp) => {
        console.log(resp);

        const respJson = JSON.stringify(resp);
        // console.log(respJson);

        sessionStorage.setItem(slugBiodiversityStore, respJson);
        localStorage.setItem(slugBiodiversitySaved, new Date().getTime());

        setDataBiodiversity(resp);
      })
      .catch((err) => {
        handleError(err);
      });
  }

  const data = useMemo(() => {
    return dataBiodiversity;
  }, [dataBiodiversity]);

  return (
    <>
      {data ? (
        <>
          <h1>
            Total de indivíduos: {convertLocaleSeparator(data.totalCount)}
          </h1>
          <h1>
            Total de espécies: {convertLocaleSeparator(data.totalSpecies)}
          </h1>
          <h1>Espécies ameaçadas: {convertLocaleSeparator(data.totalRisk)}</h1>
          <br />

          {data.dataTrees && (
            <>
              {data.dataTrees.map((spicie) => {
                return (
                  <h2 key={spicie.cod_tree}>
                    {spicie.name_public} (
                    {convertLocaleSeparator(spicie.totalUnits)})
                    <br />
                  </h2>
                );
              })}
            </>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
