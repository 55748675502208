import React, { useEffect } from "react";
import { Popup } from "react-map-gl";
import * as turf from "@turf/turf";
import { useTranslation } from "react-i18next";

import { BUTTON } from "../../assets/styles/html";
import { COLORS } from "../../assets/colors";

export default function PopUpChildren(props) {
	//
	const { t } = useTranslation();
	const { type, data } = props;

	let childPolygon = turf.polygon(data.geometry.coordinates);
	let childCenterOfMass = turf.centerOfMass(childPolygon);

	useEffect(() => {}, []);

	const openChildMap = (url) => {
		window.open(
			window.location.origin + "/" + url,
			"_blank",
			"noopener,noreferrer"
		);
	};

	return (
		<Popup
			id={data?.cod_allotment}
			longitude={childCenterOfMass.geometry.coordinates[0]}
			latitude={childCenterOfMass.geometry.coordinates[1]}
			closeButton={true}
			closeOnClick={false}
			className={type}
			anchor="bottom"
			dynamicPosition={false}>
			{/* <img
				key={data?.properties.title.toString()}
				src={data?.properties.image}
				title={data?.properties.title}
				alt={data?.properties.description}
			/> */}
			<h1>{data?.properties.title}</h1>
			<BUTTON
				style={{
					backgroundColor: COLORS.backgroundContainers,
					color: COLORS.white,
					fontSize: 4.75,
				}}
				onClick={() => {
					openChildMap(data?.properties.slug_company + "/" + data?.properties.slug);
				}}>
				{t("buttons.openMap")}
			</BUTTON>
		</Popup>
	);
}

export { PopUpChildren };
