import styled, { keyframes } from "styled-components";
import { DEVICES } from "../devices";

export const SplashScreen = styled.div`
	font-size: 1em;

	position: fixed;
	width: 100vw;
	height: 100vh;

	top: 0;
	left: 0;
	z-index: 10;

	display: flex;
	align-items: center;
	justify-content: center;

	transition: opacity 2s;
	opacity: 1;

	&.show {
		opacity: 1;
		transition: 1s ease-in;
	}

	&.hide {
		opacity: 0;
		transition: 1s ease-out;
	}

	& h1 {
		font-size: 5em;
		letter-spacing: -0.025em;
		margin-bottom: 0.25em;
	}

	& p {
		font-size: 1.75em;
		line-height: 1.125em;
		letter-spacing: -0.02em;
		text-align: center;
	}

	& .Logo {
		position: fixed;
		top: 1em;
		left: 1em;
	}

	& .Logo img {
		height: 3.25em;
		width: auto;
	}
`;

export const SplashContainer = styled.div`
	font-size: 1.25em;
	margin: auto;
	align-items: center;
	text-align: center;

	@media ${DEVICES.maxSmall} {
		font-size: 1em;
	}

	& .Image {
		margin-bottom: 3em;
	}

	& img {
		height: 12em;
		width: auto;
	}

	& .Spinner {
		padding: 2em 0 0 0;
	}

	& .Spinner img {
		height: 4em;
		width: auto;
	}
`;

export const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const SplashAd = styled.div`
	margin: auto;
	align-items: center;
	text-align: center;

	& img {
		height: 80vh;
		width: auto;
		object-fit: cover;

		border-radius: 1em;
	}

	@media ${DEVICES.maxSmall} {
		& img {
			height: 100vh;
		}
	}

	& .fadeIn {
		animation: ${fadeInAnimation} 0.25s ease-in;
	}
`;
