import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { load } from '@loaders.gl/core';
import { JSONLoader } from '@loaders.gl/json';
import { useErrorHandler } from 'react-error-boundary';

import { useParams } from 'react-router-dom';

import { CONFIGS } from '../../../assets/configs';
import { convertLocaleSeparator } from '../../../services/converters';
import { useDataMap } from '../../../contexts/datamap';
import Loading from './loading';

const API_URL =
  process.env.NODE_ENV !== 'development'
    ? CONFIGS.urlApiProduction
    : CONFIGS.urlApiLocal;

export default function Carbon() {
  const handleError = useErrorHandler();

  const { t } = useTranslation();
  const dataMapContext = useDataMap();
  const [dataCarbon, setDataCarbon] = useState();

  const routeParams = useParams();
  const slugClient = routeParams.slugClient;
  const slugLot = routeParams.slugLot;

  const slugCarbonStore = '@ForestWatch:Carbon:' + slugLot;
  const slugCarbonSaved = '@ForestWatch:Carbon:saved';

  /////////////////////////////////////////////////////////////////

  useEffect(() => {
    const sessionCarbon = sessionStorage.getItem(slugCarbonStore);

    let hours = 24;
    let saved = localStorage.getItem(slugCarbonSaved);

    // console.log(saved);
    // console.log(sessionCarbon);

    if (saved && new Date().getTime() - saved > hours * 60 * 60 * 1000) {
      localStorage.clear();
    }

    if (!sessionCarbon) {
      callApiCarbon();
    } else {
      setDataCarbon(JSON.parse(sessionCarbon));
    }
    // eslint-disable-next-line
  }, []);

  /////////////////////////////////////////////////////////////////

  async function callApiCarbon() {
    //
    let fetchURL =
      API_URL + '/api/v3/maps/' + slugClient + '/' + slugLot + '/carbon';

    await load(fetchURL, JSONLoader)
      .then((resp) => {
        console.log(resp);

        const respJson = JSON.stringify(resp);
        console.log(respJson);

        sessionStorage.setItem(slugCarbonStore, respJson);
        localStorage.setItem(slugCarbonSaved, new Date().getTime());

        setDataCarbon(resp);
      })
      .catch((err) => {
        handleError(err);
      });
  }

  const data = useMemo(() => {
    return dataCarbon;
  }, [dataCarbon]);

  return (
    <>
      {data ? (
        <>
          <h1>Potencial CO2/ano: {data.year}t /ano</h1>
          <h1>Potencial acumulado: {data.tilnow}t</h1>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
