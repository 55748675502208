import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

import { CONFIGS } from "./assets/configs";
import { useAuth } from "./contexts/auth";
import { useSplash } from "./contexts/splash";

import AuthedRoutes from "./routes/authed";
import PublicRoutes from "./routes/public";

import Splash from "./components/splash";

export default function Router() {
  //
  const languages = CONFIGS.languages;

  const { t, i18n } = useTranslation();

  const currentLocale = Cookies.get("i18next") || "br";
  const currentLangObj = languages.find((lang) => lang.code === currentLocale);

  useEffect(() => {
    document.body.dir = currentLangObj.dir || "ltr";
    document.title = t("app.title");
  }, [currentLangObj, t]);

  /////////////////////////////////////////////

  const { signed } = useAuth();
  const splashContext = useSplash();

  useEffect(() => {}, []);

  console.log("user signed", signed);

  let showSplash = splashContext.show ? <Splash /> : <></>;
  let showRoutes = signed ? <AuthedRoutes /> : <PublicRoutes />;

  return (
    <>
      {showSplash}
      {showRoutes}
    </>
  );
}
