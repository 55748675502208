// import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../assets/colors';
import { CONFIGS } from '../../assets/configs';
//
import { SplashScreen, SplashAd } from '../../assets/styles/splash';
import Button from '../button';

export default function Ad(props) {
  //
  const { ad } = props;
  const { t } = useTranslation();

  useEffect(() => {}, []);

  let adTitle = ad?.campaign ? ad.campaign : '';
  let adImage = ad?.picture ? <img src={ad.picture} alt={adTitle} /> : '';

  if (ad?.url) {
    let adUrl = ad.url + '?utm_source=ForestWatch&utm_campaign=' + ad?.urlTitle;

    adImage = (
      <a href={adUrl} title={adTitle}>
        {adImage}
      </a>
    );
  }

  return (
    <SplashScreen style={{ backgroundColor: COLORS.backgroundContainers }}>
      <SplashAd>
        <div className='fadeIn'>{adImage}</div>
      </SplashAd>
    </SplashScreen>
  );
}
