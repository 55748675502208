import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

import { useDataMap } from "../../../contexts/datamap";
import Loading from "./loading";

export default function Inspections() {
	const { t } = useTranslation();
	const dataMapContext = useDataMap();

	const [inspectionsData, setInspectionsData] = useState(null);
	const [inspectionsDateTime, setInspectionsDateTime] = useState(null);

	/////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (dataMapContext.inspectionsData) {
			setInspectionsData(dataMapContext.inspectionsData.features);
		}
		// eslint-disable-next-line
	}, [dataMapContext.inspectionsData]);

	useEffect(() => {
		if (dataMapContext.timeSeries) {
			setInspectionsDateTime(dataMapContext.timeSeries);
			console.log(dataMapContext.timeSeries);
		}
		// eslint-disable-next-line
	}, [dataMapContext.timeSeries]);

	/////////////////////////////////////////////////////////////////

	return (
		<>
			{inspectionsData ? (
				<>
					<h1>
						{t("map.menu.apiInspections.title")} ({inspectionsData.length})
					</h1>

					<h2>{t("map.menu.apiInspections.description")}</h2>

					{inspectionsData.length === 0 ?? (
						<>
							<p>{t("map.menu.apiInspections.noInspections")}</p>
						</>
					)}

					<ul>
						{inspectionsData.map((inspection) => {
							return (
								<li key={inspection.properties.cod_inspection}>
									{inspection.properties?.user} <br />
									{inspection.properties?.created_at}
								</li>
							);
						})}
					</ul>
				</>
			) : (
				<Loading />
			)}
		</>
	);
}
