import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './contexts/auth';
import { SplashProvider } from './contexts/splash';

import GlobalStyle from './assets/styles/global';
import Router from './router';

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <SplashProvider>
          <GlobalStyle />
          <Router />
        </SplashProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
