import api from "../services/api";

export async function signIn(email, password) {
  if (email && password) {
    const response = await api.post("/api/auth/signin", {
      email: email,
      password: password,
    });
    return response.data;
  } else {
    throw new Error("no crendentials");
  }
}
