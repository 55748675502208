import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { load } from "@loaders.gl/core";
import { JSONLoader } from "@loaders.gl/json";
import { useErrorHandler } from "react-error-boundary";

import { CONFIGS } from "../../../assets/configs";
import {
  convertUnixToDate,
  convertLocaleSeparator,
  convertKelvinToCelsius,
} from "../../../services/converters";

import { useDataMap } from "../../../contexts/datamap";
import Loading from "./loading";

export default function WeatherAgro() {
  const handleError = useErrorHandler();

  const { t } = useTranslation();
  const dataMapContext = useDataMap();
  const [dataWeather, setDataWeather] = useState();

  /////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (dataMapContext.dataLotProperties) {
      callApiWeather();
    }
    // eslint-disable-next-line
  }, [dataMapContext.dataLotProperties, dataMapContext.timeSeries]);

  /////////////////////////////////////////////////////////////////

  async function callApiWeather() {
    //
    let fetchURL =
      "https://api.agromonitoring.com/agro/1.0/weather?lat=" +
      dataMapContext.dataLotProperties.centerOfMass[1] +
      "&lon=" +
      dataMapContext.dataLotProperties.centerOfMass[0] +
      "&appid=" +
      CONFIGS.agroAPIToken;

    await load(fetchURL, JSONLoader)
      .then((resp) => {
        console.log(resp);
        setDataWeather(resp);
      })
      .catch((err) => {
        handleError(err);
      });
  }

  return (
    <>
      {dataWeather ? (
        <>
          <p>Weather, clima: {dataWeather.weather[0]?.main}</p>
          <p>Weather, descrição: {dataWeather.weather[0]?.description}</p>
          <p>
            Weather, temperatura:{" "}
            {convertKelvinToCelsius(dataWeather.main?.temp)}
          </p>
          <p>
            Weather, sensação:{" "}
            {convertKelvinToCelsius(dataWeather.main?.feels_like)}
          </p>
          <p>
            Weather, vento: {convertLocaleSeparator(dataWeather.wind?.speed)}m/s
          </p>
          <p>Weather, umidade: {dataWeather.main?.humidity}%</p>
          <p>
            Weather, pressão:{" "}
            {convertLocaleSeparator(dataWeather.main?.pressure)} hPa
          </p>
          <p>Weather, cobertura: {dataWeather.clouds?.all}%</p>
          <p>Weather, Rain 3h: {dataWeather.rain?.rain}%</p>
          <p>DT: {convertUnixToDate(dataWeather.dt)}</p>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
