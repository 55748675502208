export const convertKelvinToCelsius = (temp) => {
  return (Math.trunc((temp - 273.15) * 100) / 100).toLocaleString() + ' ºC';
};

export const convertUnixToDate = (dt, onlydate) => {
  let date = new Date(dt * 1000);

  let dateReturn =
    date.getDate().toString().padStart(2, '0') +
    '/' +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    '/' +
    date.getFullYear();

  if (!onlydate)
    dateReturn +=
      ' ' +
      date.getHours().toString().padStart(2, '0') +
      ':' +
      date.getMinutes().toString().padStart(2, '0');
  // + ":" + date.getSeconds().toString().padStart(2, "0");

  // return new Date(dt * 1e3).toISOString();
  return dateReturn;
};

export const convertLocaleSeparator = (number) => {
  return number.toLocaleString();
};

export const convertLongToShort = (number, length) => {
  return parseFloat(number.toFixed(length));
};

export const convertLatLonGeoJSON = (latitude, longitude, properties) => {
  let geoJSONTemplate = {
    type: 'Feature',
    properties,
    geometry: {
      type: 'Point',
      coordinates: [longitude, latitude],
    },
  };
  return geoJSONTemplate;
};
