import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Default from "../pages/default";
import Public from "../pages/public";
import RootMap from "../pages/rootmap";
import HttpStatus from "../pages/httpStatus";

export default function PublicRoutes() {
  let location = useLocation();

  return (
    <Routes location={location}>
      <Route path="/" element={<Default />}></Route>
      <Route path="/public" element={<Public />}></Route>
      <Route path="/:slugClient/:slugLot" element={<RootMap />}></Route>
      <Route path="*" element={<HttpStatus />} />
    </Routes>
  );
}
