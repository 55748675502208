import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { load } from "@loaders.gl/core";
import { JSONLoader } from "@loaders.gl/json";
import { useErrorHandler } from "react-error-boundary";
import { DateTime } from "luxon";

import { CONFIGS } from "../../../assets/configs";
import {
  convertUnixToDate,
  convertLocaleSeparator,
} from "../../../services/converters";

import { useDataMap } from "../../../contexts/datamap";

import Loading from "./loading";

export default function Weather() {
  const handleError = useErrorHandler();

  const { t } = useTranslation();
  const dataMapContext = useDataMap();
  const [dataWeather, setDataWeather] = useState(null);

  /////////////////////////////////////////////////////////////////

  useEffect(() => {
    callApiWeather();
    // eslint-disable-next-line
  }, [dataMapContext.timeSeriesSelected]);

  /////////////////////////////////////////////////////////////////

  async function callApiWeather() {
    //
    let fetchURL =
      "https://api.openweathermap.org/data/2.5/onecall?lat=" +
      dataMapContext.dataLotProperties.centerOfMass[1] +
      "&lon=" +
      dataMapContext.dataLotProperties.centerOfMass[0] +
      "&exclude=minutely,hourly,daily&units=metric&lang=pt_br" +
      "&appid=" +
      CONFIGS.openWeatherToken;

    await load(fetchURL, JSONLoader)
      .then((resp) => {
        setDataWeather(resp);
      })
      .catch((err) => {
        console.log("ERROR ***", err);
        handleError(err);
      });
  }

  function returnOnlyHour(time) {
    let fromUT = DateTime.fromSeconds(time);
    let timeObject = fromUT.toObject();
    return timeObject.hour + ":" + timeObject.minute;
  }

  return (
    <>
      {dataWeather ? (
        <>
          {/* {dataWeather.current?.weather[0] && (
            <>
              <img
                src={
                  "https://openweathermap.org/img/wn/" +
                  dataWeather.current.weather[0].icon +
                  "@4x.png"
                }
                alt={dataWeather.current.weather[0].main}
                style={{ width: "6em", height: "auto" }}
              />
              <p>{dataWeather.current.weather[0].main}</p>
              <p>{dataWeather.current.weather[0].description}</p>
              <br />
            </>
          )} */}

          <p>Nascer do Sol: {returnOnlyHour(dataWeather.current?.sunrise)}</p>
          <p>Por do Sol: {returnOnlyHour(dataWeather.current?.sunset)}</p>
          <p>
            Temperatura: {convertLocaleSeparator(dataWeather.current?.temp)}ºC
          </p>
          <p>
            Sensação: {convertLocaleSeparator(dataWeather.current?.feels_like)}
            ºC
          </p>
          <p>
            Pressão: {convertLocaleSeparator(dataWeather.current?.pressure)} hPa
          </p>
          <p>Umidade: {dataWeather.current?.humidity}%</p>
          <p>Orvalho: {dataWeather.current?.dew_point}ºC</p>
          <p>Nuvens: {dataWeather.current?.clouds}%</p>
          <p>UV: {dataWeather.current?.uvi}</p>
          <p>Velocidade do vento: {dataWeather.current?.wind_speed} m/s</p>
          <p>
            Visibilidade:{" "}
            {convertLocaleSeparator(dataWeather.current?.visibility / 1000)}km
          </p>
          <p>DT: {convertUnixToDate(dataWeather.current?.dt)}</p>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
