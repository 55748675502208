import React, {
	createContext,
	useContext,
	useState,
	useRef,
	useMemo,
	useEffect,
} from "react";
import * as turf from "@turf/turf";
import { DateTime } from "luxon";

import { load } from "@loaders.gl/core";
import { JSONLoader } from "@loaders.gl/json";
import { CSVLoader } from "@loaders.gl/csv";

// import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CONFIGS } from "../assets/configs";
import { useSplash } from "./splash";

import { convertLatLonGeoJSON } from "../services/converters";

const DataMapContext = createContext();

let curretDomain = window.location.host.split(".")[0];

const currentEnvironment =
	curretDomain.search("localhost") < 0 && curretDomain.search("dev") < 0
		? "prod"
		: "dev";

const DataMapProvider = function ({ children }) {
	//
	const { t } = useTranslation();
	const splashContext = useSplash();
	const calledOnce = useRef(false);

	// const [lotData, setLotData] = useState(null);
	const [lotImagery, setLotImagery] = useState(null);
	const [lotProperties, setLotProperties] = useState(null);

	const [timeSeries, setTimeSeries] = useState({});
	const [timeSeriesSelected, setTimeSeriesSelected] = useState();
	const [timeSeriesSelectedIndex, setTimeSeriesSelectedIndex] = useState();

	const [satelliteCloudCoverage, setSatelliteCloudCoverage] = useState(5);

	const [rasterLayerOpacity, setRasterLayerOpacity] = useState(0.25);
	const [rasterLayerIndex, setRasterLayerIndex] = useState(0);

	const [dataFire, setDataFire] = useState(null);
	const [dataFireTemp, setDataFireTemp] = useState(null);
	const [mousePositionLatLong, setMousePositionLatLong] = useState(null);

	const [apiPermissions, setApiPermissions] = useState();
	const [menuActive, setMenuActive] = useState();
	const [openSearch, setOpenSearch] = useState(false);
	const [openSearchToken, setOpenSearchToken] = useState(false);

	const [deforestationData, setDeforestationData] = useState([]);
	const [inspectionsData, setInspectionsData] = useState([]);

	const [notificationData, setNotificationData] = useState({});

	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////

	// GLOBAL TIMER

	const timerReload = 300;
	const [timerTime, setTimerTime] = useState(0);
	const [timerReset, setTimerReset] = useState(false);

	useEffect(() => {
		setTimerTime(timerReload);

		let timer = setInterval(() => {
			setTimerTime((timerTime) => {
				if (timerTime === 0) {
					clearInterval(timer);
					setTimerReset(!timerReset);
					getNasaFire();
				} else {
					return timerTime - 1;
				}
			});
		}, 1000);
	}, [timerReset]);

	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////

	// get data first mount
	useEffect(() => {
		if (calledOnce.current) {
			return;
		}
		// getLotData();
		getTimesSeries();
		calledOnce.current = true;
		// eslint-disable-next-line
	}, []);

	// get data first mount
	useEffect(() => {
		if (lotImagery !== null) {
			let timeSeries = lotImagery.length - 1;
			selecTimeSeries(timeSeries);
		} else {
			setTimeSeriesSelected(null);
			setTimeSeriesSelectedIndex(null);
		}
		// eslint-disable-next-line
	}, [lotImagery]);

	// get satellite imagery data IF have API flag enabled
	useEffect(() => {
		const apiSatellitePermission = getLotPermission(
			CONFIGS.apiPermissions.apiSatellite.code
		);

		if (apiSatellitePermission === true) {
			if (dataImagery === null) {
				getImageryData();
			}
		}

		// eslint-disable-next-line
	}, [lotProperties]);

	// if timeSeries is changed
	useEffect(() => {
		console.log("timeSeries or satelliteCloudCoverage has changed");
		getImageryData();
		// eslint-disable-next-line
	}, [timeSeries, satelliteCloudCoverage]);

	// call NASA fire api
	useEffect(() => {
		if (
			lotProperties &&
			!dataFire &&
			getLotPermission(CONFIGS.apiPermissions.apiFire.code)
		) {
			getNasaFire();
		}
		// eslint-disable-next-line
	}, [lotProperties]);

	// response of NASA fire api
	useEffect(() => {
		//
		if (dataFireTemp && Object.entries(dataFireTemp).length > 0) {
			//
			let allFireData = [];

			Object.keys(dataFireTemp).forEach((fire) => {
				let fireData = dataFireTemp[fire];
				let fireNetwork = fire;

				fireData.forEach((fire) => {
					let properties = { ...fire, network: fireNetwork };
					let feature = convertLatLonGeoJSON(
						fire.latitude,
						fire.longitude,
						properties
					);

					allFireData.push(feature);
					// return feature;
				});
			});

			//////////////////////////////////////////////////////////

			setNotificationData({
				...notificationData,
				apiFire: allFireData.length > 0 ? true : false,
			});

			//////////////////////////////////////////////////////////

			if (lotProperties?.polygonBuffer) {
				allFireData.push(lotProperties.polygonBuffer);
			}

			allFireData = {
				type: "FeatureCollection",
				features: allFireData,
			};

			setDataFire(allFireData);

			//////////////////////////////////////////////////////////
		}

		// eslint-disable-next-line
	}, [dataFireTemp]);

	// set permissions
	useEffect(() => {
		if (lotProperties) {
			let lotPermissions = lotProperties.properties?.apiPermissions
				? lotProperties.properties.apiPermissions
				: null;

			const globalPermissions = CONFIGS.apiPermissions;
			let lotPermissionsGranted = [];

			if (lotPermissions) {
				Object.entries(globalPermissions).forEach((permission) => {
					permission = permission[1];
					let hasPermission = lotPermissions.search(permission.code);
					if (hasPermission >= 0) lotPermissionsGranted.push(permission);
				});
			}

			setApiPermissions(lotPermissionsGranted);
			console.log("lotPermissionsGranted", lotPermissionsGranted);
			console.log("lotProperties", lotProperties);
			console.log("lotProperties.boundBox", lotProperties.boundBox);
		}
		// eslint-disable-next-line
	}, [lotProperties]);

	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////

	async function getImageryData() {
		if (dataLotProperties?.polygonAgroAPIToken) {
			let fetchURL =
				"https://api.agromonitoring.com/agro/1.0/image/search?start=" +
				timeSeries.start +
				"&end=" +
				timeSeries.end +
				"&polyid=" +
				dataLotProperties.polygonAgroAPIToken +
				"&appid=" +
				CONFIGS.agroAPIToken +
				"&clouds_max=" +
				satelliteCloudCoverage +
				"&coverage_min=100";

			await load(fetchURL, JSONLoader)
				.then((resp) => {
					let imagery = resp;
					console.log("imagery", imagery);
					setLotImagery(imagery);
				})
				.catch((err) => {
					console.log("err", err);

					let splashConfig = {
						type: CONFIGS.splash.types.Error,
						title: t("splash.error.title"),
						body: "Error getting Satellite Imagery from our AgroAPI", //t("splash.error.body")
						timer: false,
					};
					splashContext.openSplash(splashConfig);
				});
		}
	}

	async function getTimesSeries(time) {
		//
		let dateStart = DateTime.now().minus({ hours: 1 });
		let dateEnd = DateTime.now().minus({ months: CONFIGS.agroAPIPeriod - 1 });

		dateStart = (dateStart.ts + "").slice(0, -3);
		dateEnd = (dateEnd.ts + "").slice(0, -CONFIGS.agroAPIPeriod);

		if (time) {
			dateStart = Date.parse(time[1]);
			dateEnd = Date.parse(time[0]);

			dateStart = (dateStart + "").slice(0, -3) * 1;
			dateEnd = (dateEnd + "").slice(0, -CONFIGS.agroAPIPeriod) * 1;
		}

		let timeSeries = {
			end: dateStart,
			start: dateEnd,
		};

		console.log("timeSeries", timeSeries);

		if (dateStart && dateEnd) {
			setTimeSeries(timeSeries);
		}
	}

	///////////////////////////////////////////////////////////////

	// select time series
	function selecTimeSeries(selected) {
		if (lotImagery) {
			let selectedTimeSeries = lotImagery[selected];
			setTimeSeriesSelected(selectedTimeSeries);
			console.log("rasterLayerIndex", rasterLayerIndex);
			// setRasterLayerIndex("");
			setTimeSeriesSelectedIndex(selected);
		}
	}

	// get fire api data
	async function getNasaFire() {
		//
		let tempDataFire = [];
		let lotPolygonBufferedBBox = lotProperties?.boundBoxBuffered
			? lotProperties.boundBoxBuffered
			: lotProperties?.boundBox;

		if (!lotPolygonBufferedBBox) {
			console.log("ERROR: NASA Fire API without BBox");
			return;
		}

		let showFakeFires = false; // currentEnvironment !== "dev" ? false : true;
		const nasaFireServiceList = CONFIGS.nasaFireAPI.servicesList;

		// get data fire
		if (!showFakeFires) {
			nasaFireServiceList.forEach(async (service) => {
				//
				let tokenList = CONFIGS.nasaFireAPI.tokenList;
				let randomToken = Math.floor(Math.random() * tokenList.length);
				let tokenCall = tokenList[randomToken];

				// console.log("*****************************************************");
				// console.log("NASA FIRMS TOKEN CALL", tokenCall);

				const fetchURL =
					"https://firms.modaps.eosdis.nasa.gov/api/area/csv/" +
					tokenCall +
					"/" +
					service +
					"/" +
					lotPolygonBufferedBBox[0] +
					"," +
					lotPolygonBufferedBBox[1] +
					"," +
					lotPolygonBufferedBBox[2] +
					"," +
					lotPolygonBufferedBBox[3] +
					"/10";

				await load(fetchURL, CSVLoader)
					.then((resp) => {
						tempDataFire[service] = resp;
						setDataFireTemp({ ...tempDataFire });
					})
					.catch((err) => {
						let splashConfig = {
							type: CONFIGS.splash.types.Alert,
							title: "NASA API Fire NRT", //t("splash.alert.title")
							body: "Error getting Fire Data from NASA API", //t("splash.error.body")
							timer: true,
						};

						console.log("err", err);
						console.log("err splash", splashConfig);
						splashContext.openSplash(splashConfig);
					});
			});
		} else {
			// dados fake
			const fakeFirePoints = [];
			nasaFireServiceList.forEach(async (service) => {
				//

				let randomTargets = Math.floor(Math.random() * 4) + 1;
				let randomPoints = turf.randomPoint(randomTargets, {
					bbox: lotPolygonBufferedBBox,
				});

				randomPoints = randomPoints.features;
				randomPoints.map((point) => {
					let pointFake = {
						acq_date: new Date().toLocaleDateString(),
						acq_time: 1732,
						bright_ti4: 348.43,
						bright_ti5: 309.02,
						confidence: "n",
						daynight: "D",
						frp: (Math.random() * (9 - 3) + 3).toFixed(2) * 1,
						instrument: service,
						latitude: point.geometry.coordinates[1],
						longitude: point.geometry.coordinates[0],
						satellite: 1,
						scan: 0.47,
						track: 0.48,
						version: "2.0NRT",
					};
					fakeFirePoints.push(pointFake);
					return null;
				});
			});

			tempDataFire = fakeFirePoints;
			console.log("NASA FAKE FIRE POINTS", tempDataFire.length);
		}
		//
	}

	// get permissions from lot
	function getLotPermission(apiLabel) {
		console.log("getLotPermission", apiLabel);

		if (lotProperties) {
			let apiPermissions = lotProperties.properties?.apiPermissions
				? lotProperties.properties.apiPermissions
				: null;

			if (apiPermissions && apiLabel) {
				let hasPermission = apiPermissions.search(apiLabel);
				if (hasPermission >= 0) return true;
				return false;
			}
			return false;
		}

		return false;
	}

	// async function setLotPropertiesData(lotPropertiesData) {
	//   setLotProperties(lotPropertiesData);
	// }

	function setMousePosition(position) {
		setMousePositionLatLong(position);
	}

	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////

	// const dataLot = useMemo(() => {
	//   return lotData;
	// }, [lotData]);

	const dataImagery = useMemo(() => {
		return lotImagery;
	}, [lotImagery]);

	const dataLotProperties = useMemo(() => {
		return lotProperties;
	}, [lotProperties]);

	const dataLotFire = useMemo(() => {
		return dataFire;
	}, [dataFire]);

	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////

	return (
		<DataMapContext.Provider
			value={{
				dataLotProperties,
				dataImagery,
				dataLotFire,
				timeSeries,
				timeSeriesSelected,
				timeSeriesSelectedIndex,
				getTimesSeries,
				selecTimeSeries,
				setLotProperties,
				rasterLayerOpacity,
				rasterLayerIndex,
				setRasterLayerOpacity,
				setRasterLayerIndex,
				setMousePosition,
				mousePositionLatLong,
				satelliteCloudCoverage,
				setSatelliteCloudCoverage,
				apiPermissions,
				getLotPermission,
				menuActive,
				setMenuActive,
				openSearch,
				setOpenSearch,
				openSearchToken,
				setOpenSearchToken,
				deforestationData,
				setDeforestationData,
				inspectionsData,
				setInspectionsData,
				timerTime,
				notificationData,
				setNotificationData,
			}}>
			{children}
		</DataMapContext.Provider>
	);
};

function useDataMap() {
	const context = useContext(DataMapContext);

	if (!context) {
		throw new Error("useSplash must be used within an DataMapProvider");
	}

	return context;
}

export { DataMapProvider, useDataMap };
