import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import { CONFIGS } from '../assets/configs';
import { useSplash } from '../contexts/splash';

export default function Default() {
  //
  const splashContext = useSplash();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    splashContext.closeSplash();
  }, []);

  /////////////////////////////////////////////////////////////////////
  // localization

  const languages = CONFIGS.languages;

  const currentLocale = Cookies.get('i18next') || 'br';
  const [language, setLanguage] = useState(currentLocale);

  function handleChangeLocale(e) {
    const lang = e.target.value;
    setLanguage(lang);
    i18n.changeLanguage(lang);

    splashContext.setLanguage(lang);

    let splashConfig = {
      type: CONFIGS.splash.types.Loading,
      title: t('splash.loading.title'),
      body: t('splash.loading.body'),
      timer: true,
    };
    splashContext.openSplash(splashConfig);
  }

  return (
    <>
      <h1>{t('app.title')}</h1>
      <h2>{t('app.description')}</h2>
      <br />

      <select onChange={handleChangeLocale} value={language}>
        {languages.map(({ name, code }) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </select>
    </>
  );
}
