export const COLORS = {
	transparent: "transparent",
	white: "#fff",
	black: "#000",
	backgroundContainers: "rgba(12, 12, 12, 0.875)",

	error: "#e85b5b",
	success: "#8bc056",
	alert: "#ffd400",

	ice: "#f0f0e6",
	sand: "#d5d498",
	petrol: "#0b768c",

	green_light: "#c3d032",
	green_smooth: "#8bc056",
	green_dark: "#2ba63f",

	gray_superlight: "#e1e1e0",
	gray_midlight: "#a8a8a8",
	gray_light: "#595857",
	gray_mid: "#2e2e2e",
	gray_dark: "#1c1c1c",

	device: {
		border: "#3cb64b",
		center: "#00ff18",
	},

	pattern1: {
		color00: "#F33A41",
		color01: "#FC9720",
		color02: "#F1D929",
		color03: "#28CB69",
		color04: "#219CFD",
		color05: "#7D24FC",
		colorNull: "#CECECE",
	},
	pattern1b: {
		color00: "#7D24FC",
		color01: "#219CFD",
		color02: "#28CB69",
		color03: "#F1D929",
		color04: "#FC9720",
		color05: "#F33A41",
		colorNull: "#CECECE",
	},

	pattern2: {
		color00: "#3288bd",
		color01: "#66c2a5",
		color02: "#abdda4",
		color03: "#e6f598",
		color04: "#ffffbf",
		color05: "#fffdec",
		colorNull: "#999999",
	},

	pattern3: {
		color00: "#fffdec",
		color01: "#ffffbf",
		color02: "#fee08b",
		color03: "#fdae61",
		color04: "#f46d43",
		color05: "#d53e4f",
		colorNull: "#999999",
	},

	pattern4: {
		color00: "#8274d1",
		color01: "#c863b3",
		color02: "#df5d99",
		color03: "#ed6561",
		color04: "#e18731",
		color05: "#d7ae1f",
		colorNull: "#999999",
	},

	pattern5: {
		color00: "#4698bc",
		color01: "#48aa9f",
		color02: "#6bbb5d",
		color03: "#d7ae1f",
		color04: "#ee7447",
		color05: "#c863b3",
		colorNull: "#999999",
	},

	pattern6: {
		color00: "#F4E784",
		color01: "#F1CF77",
		color02: "#EEB76A",
		color03: "#EBA05E",
		color04: "#E88851",
		color05: "#E47044",
		color06: "#E15837",
		color07: "#DE412B",
		color08: "#DB291E",
		color09: "#D81111",
		colorNull: "#999999",
	},

	pattern7: {
		color00: "#007F5F",
		color01: "#2B9348",
		color02: "#55A630",
		color03: "#80B918",
		color04: "#AACC00",
		color05: "#BFD200",
		color06: "#D4D700",
		color07: "#DDDF00",
		color08: "#EEEF20",
		color09: "#FFFF3F",
		colorNull: "#999999",
	},

	pattern8: {
		color00: "#D9ED92",
		color01: "#B5E48C",
		color02: "#99D98C",
		color03: "#76C893",
		color04: "#52B69A",
		color05: "#34A0A4",
		color06: "#168AAD;",
		color07: "#1A759F",
		color08: "#1E6091",
		color09: "#184E77",
		colorNull: "#999999",
	},

	pattern9: {
		color00: "#03071E",
		color01: "#370617",
		color02: "#6A040F",
		color03: "#9D0208",
		color04: "#D00000",
		color05: "#DC2F02",
		color06: "#E85D04",
		color07: "#F48C06",
		color08: "#FAA307",
		color09: "#FFBA08",
		colorNull: "#999999",
	},

	pattern10: {
		color00: "#F72585",
		color01: "#B5179E",
		color02: "#7209B7",
		color03: "#560BAD",
		color04: "#480CA8",
		color05: "#3A0CA3",
		color06: "#3F37C9",
		color07: "#4361EE",
		color08: "#4895EF",
		color09: "#4CC9F0",
		colorNull: "#999999",
	},

	pattern11: {
		color00: "#F94144",
		color01: "#F3722C",
		color02: "#F8961E",
		color03: "#F9C74F",
		color04: "#90BE6D",
		color05: "#43AA8B",
		color06: "#577590",
		colorNull: "#999999",
	},

	pattern12: {
		color00: "#FEB538",
		color01: "#FF941C",
		color02: "#FF7200",
		color03: "#F86019",
		color04: "#F04E31",
		color05: "#E93C4A",
		color06: "#E12A62",
		color07: "#B8215E",
		color08: "#8E1859",
		color09: "#3B054F",
		colorNull: "#999999",
	},

	pattern13: {
		color00: "#FFF95B",
		color01: "#FFEF55",
		color02: "#FFE950",
		color03: "#FFDF4B",
		color04: "#FFD645",
		color05: "#FFCF40",
		color06: "#FFC137",
		color07: "#FFB22B",
		color08: "#FFA21F",
		color09: "#FF930F",
		colorNull: "#999999",
	},

	pattern14: {
		color00: "#FFCF40",
		color01: "#FBBD3E",
		color02: "#F8AC3C",
		color03: "#F49A3B",
		color04: "#F18839",
		color05: "#ED7737",
		color06: "#EA6535",
		color07: "#E65334",
		color08: "#E34232",
		color09: "#DF3030",
		colorNull: "#999999",
	},
};
