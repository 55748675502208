import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MapWidgetLoading } from "../../../assets/styles/map";

export default function Loading({ title }) {
	const { t } = useTranslation();
	useEffect(() => {
		// eslint-disable-next-line
	}, []);

	return <MapWidgetLoading>{title ? title : "Loading..."}</MapWidgetLoading>;
}
