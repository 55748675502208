import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { load } from "@loaders.gl/core";
import { JSONLoader } from "@loaders.gl/json";
import { useErrorHandler } from "react-error-boundary";

import { CONFIGS } from "../../../assets/configs";
import { convertLocaleSeparator } from "../../../services/converters";
import { useDataMap } from "../../../contexts/datamap";
import Loading from "./loading";

export default function Precipitation() {
	const handleError = useErrorHandler();

	const { t } = useTranslation();
	const dataMapContext = useDataMap();
	const [dataPrecipitation, setDataPrecipitation] = useState();

	/////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (dataMapContext.dataLotProperties && dataMapContext.timeSeries) {
			callApiPreciptation();
		}

		// eslint-disable-next-line
	}, [dataMapContext.dataLotProperties, dataMapContext.timeSeries]);

	/////////////////////////////////////////////////////////////////

	async function callApiPreciptation() {
		//
		let fetchURL =
			"https://api.agromonitoring.com/agro/1.0/weather/history/accumulated_precipitation?start=" +
			dataMapContext.timeSeries.start +
			"&end=" +
			dataMapContext.timeSeries.end +
			"&lat=" +
			dataMapContext.dataLotProperties.centerOfMass[1] +
			"&lon=" +
			dataMapContext.dataLotProperties.centerOfMass[0] +
			"&appid=" +
			CONFIGS.agroAPIToken;

		await load(fetchURL, JSONLoader)
			.then(async (resp) => {
				let total = 0;
				await console.log(resp.json());
				resp.map((value) => (total = total + value.rain));
				setDataPrecipitation(total);
			})
			.catch((err) => {
				handleError(err);
			});
	}

	return (
		<>
			{dataPrecipitation ? (
				<h1>
					Precipitação acumulada: {convertLocaleSeparator(dataPrecipitation)}
					mm/{CONFIGS.agroAPIPeriod * 90}d
				</h1>
			) : (
				<Loading />
			)}
		</>
	);
}
