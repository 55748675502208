import "mapbox-gl/dist/mapbox-gl.css";
import "../assets/styles/mapbox-gl.css"; // must stay in place for popup
import "../assets/styles/mapbox-gl-custom.css"; // must stay in place for popup

import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

import { forEach, load } from "@loaders.gl/core";
import { JSONLoader } from "@loaders.gl/json";
import * as turf from "@turf/turf";

import { useTranslation } from "react-i18next";
import { Layer, Map as MapReact, Source, useMap, Marker } from "react-map-gl";
import { useParams } from "react-router-dom";

import { CONFIGS } from "../assets/configs";

import { useSplash } from "../contexts/splash";
import { useDataMap } from "../contexts/datamap";

import { usePosition } from "../services/userlocation";

import {
	clusterLayer,
	clusterCountLayer,
	pointsLayer,
	pointsLineLayer,
	pointsLabelLayer,
	pointsFireLayer,
	polygonLotLineLayer,
	polygonLotFillLayer,
	polygonTagFillLayer,
	polygonTagLineLayer,
	polygonTagFillExtrusionLayer,
	polygonLotFireLineLayer,
	polygonLotChildrenLineLayer,
	polygonDeforestationFillLayer,
	polygonDeforestationLineLayer,
	polygonDeviceFillLayer,
	satelliteImageryLayer,
	mapboxBuildings3dLayer,
	mapboxWaterBodiesLayer,
	mapboxPlacesLayer,
	mapboxAdminLayer,
	pointsFireInpeLayer,
	layerPointsName,
	pointsLayerImage,
	layerSource,
	wmtsLayerTest,
	wmtsLayerSource,
	wmtsGibsSource,
	wmtsGibsLayer,
	wmtsGibsSourceVector,
	wmtsGibsLayerVector,
	wmtsSentinelSource,
	wmtsSentinelLayer,
	polygonTagPatternLayer,
	pointsInspectionLayer,
	pointsInspectionLineLayer,
	pointsInspectionLabelLayer,
} from "../components/map/layers";

import PopUp from "../components/map/popup";
import Container from "../components/map/container";
import Ad from "../components/ad";
import AdMini from "../components/ad/mini";

import PopUpChildren from "../components/map/popupChildren";
import svgToMap, { svgIconsMapList } from "../services/svgtomap";

const MAPBOX_TOKEN = CONFIGS.mapboxToken;
const API_URL =
	process.env.NODE_ENV !== "development"
		? CONFIGS.urlApiProduction
		: CONFIGS.urlApiLocal;

export default function Map() {
	//
	const calledOnce = useRef(false);

	const splashContext = useSplash();
	const dataMapContext = useDataMap();

	const routeParams = useParams();
	// const ndviActive = new URLSearchParams(window.location.search).get("ndvi");

	const { forestMap } = useMap();
	const { t } = useTranslation();

	const slugClient = routeParams.slugClient;
	const slugLot = routeParams.slugLot;

	const [viewState, setViewState] = useState({
		latitude: 0,
		longitude: 0,
		zoom: 4,
		bearing: 0,
		pitch: 0,
		// projection: "globe",
	});

	const [showPopupTree, setShowPopupTree] = useState(false);
	const [viewPopupTree, setViewPopupTree] = useState({});

	const [mapData, setMapData] = useState(null);
	const [mapDataFire, setMapDataFire] = useState(null);
	const [mapDataChildrens, setMapDataChildrens] = useState(null);
	const [mapRasterUrl, setMapRasterUrl] = useState();
	const [mapMaxPitch, setMapMaxPitch] = useState(80);

	const [lotDataPopup, setLotDataPopup] = useState(null);
	const [showPopupLot, setShowPopupLot] = useState(false);
	const [showPopupAtAll, setShowPopupAtAll] = useState(true);
	const [showOffSplash, setShowOffSplash] = useState(false);

	const [speciesData, setSpeciesData] = useState(null);
	const speciesDataTrees = useRef();

	const [showBuildings, setShowBuildings] = useState(false);
	const [showFireDetection, setShowFireDetection] = useState(false);
	const [deforestationData, setDeforestationData] = useState(null);
	const [inspectionsData, setInspectionsData] = useState([]);

	const [adData, setAdData] = useState(null);
	const [adShow, setAdShow] = useState(false);

	const [userLocationGeoJson, setUserLocationGeoJson] = useState({});
	const userLocation = usePosition(true);

	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////
	// all functions

	// retrive lot geometry, data, permissions and points+polygons
	async function getData() {
		//
		await load(
			API_URL + "/api/v3/maps/" + slugClient + "/" + slugLot + "/features",
			JSONLoader,
			{
				fetch: {
					headers: {
						"Content-Type": "application/json",
						"X-Requested-With": "XMLHttpRequest",
					},
					crossDomain: true,
				},
			}
		)
			.then(async (res) => {
				//
				let response = res;
				console.log("response", response);

				//bound
				let mainPolygon = response.features.filter((feature) => {
					return feature.properties?.children === false;
				});

				mainPolygon = mainPolygon[0];
				// console.log("mainPolygon", mainPolygon);

				let boundBox;
				let centerOfGeoJSON;

				if (mainPolygon.geometry.coordinates.length !== 0) {
					boundBox = turf.bbox(mainPolygon);
					centerOfGeoJSON = turf.centerOfMass(mainPolygon);
				} else {
					boundBox = turf.bbox(response);
					centerOfGeoJSON = turf.centerOfMass(response);
				}

				console.log("boundBox", boundBox);

				// setting values for map's viewstate
				// center
				let centerOfMassCoords = [
					centerOfGeoJSON.geometry.coordinates[0],
					centerOfGeoJSON.geometry.coordinates[1],
				];

				// console.log("center of mass", centerOfMassCoords);

				setViewState({
					longitude: centerOfGeoJSON.geometry.coordinates[0],
					latitude: centerOfGeoJSON.geometry.coordinates[1],
					zoom: 11,
					bearing: 0,
					pitch: 0,
					projection: "globe",
				});

				// get lot data
				let lotTempData = mainPolygon;

				// let lotTempData = response.features.filter(function (feature) {
				//   return feature.properties.lot === true;
				// });
				// lotTempData = lotTempData[0];

				// console.log("lotTempData", lotTempData);

				// polygonToken for agroAPI
				let polygonAgroAPIToken = null;
				if (lotTempData.properties?.registers) {
					polygonAgroAPIToken = lotTempData.properties.registers.filter(
						(register) => {
							return register.type === "agroAPI";
						}
					);

					if (polygonAgroAPIToken.length > 0) {
						polygonAgroAPIToken = polygonAgroAPIToken[0].value;
					}
				}

				// console.log(
				// 	"lotTempData.geometry.coordinates",
				// 	lotTempData.geometry.coordinates
				// );

				// console.log(
				// 	" lotTempData.geometry.coordinates.length",
				// 	lotTempData.geometry.coordinates.length
				// );

				// console.log("lotTempData.geometry.type", lotTempData.geometry.type);

				let lotPolygon = null;
				let lotPolygonType = lotTempData.geometry.type;

				switch (lotPolygonType) {
					case "Polygon":
						lotPolygon = turf.polygon(lotTempData.geometry.coordinates);
						break;

					case "MultiPolygon":
						lotPolygon = turf.multiPolygon(lotTempData.geometry.coordinates);
						break;

					default:
						lotPolygon = turf.concave(response);
						break;
				}

				// console.log("lotPolygon", lotPolygon);

				let lotPolygonBufferedBBox = null;
				let lotPolygonBuffered = null;

				if (lotPolygon.geometry.coordinates.length > 0) {
					lotPolygonBuffered = turf.buffer(lotPolygon, CONFIGS.nasaFireAPI.buffer);
					lotPolygonBuffered.properties.buffer = true;
					lotPolygonBufferedBBox = turf.bbox(lotPolygonBuffered);
				}

				let lotProperties = {
					centerOfMass: centerOfMassCoords,
					boundBox: boundBox,
					boundBoxBuffered: lotPolygonBufferedBBox,
					polygonBuffer: lotPolygonBuffered,
					polygonAgroAPIToken: polygonAgroAPIToken,
					...lotTempData,
				};

				// console.log("lotProperties", lotProperties);

				// set lot data with new infos
				setShowPopupLot(true);

				/////////////////////////////////////////////////////
				// add polygon for those that don't have

				await response.features.map(async (feature) => {
					if (feature.properties?.children === true) {
						//
						let childrenCod = feature.properties.cod_allotment;
						let childrenFeaturesCollection = [];
						let childrenFeaturesCollectionFirst = [];
						let hasPolygon = feature.geometry?.coordinates[0]
							? feature.geometry?.coordinates[0]
							: [];
						let circle = 0;

						if (hasPolygon.length > 0) return true;

						await response.features.map((feature) => {
							if (
								feature.properties.cod_allotment === childrenCod &&
								feature.geometry?.type === "Point" &&
								feature.geometry?.coordinates
							) {
								if (circle === 0) {
									childrenFeaturesCollectionFirst = feature.geometry.coordinates;
								}
								childrenFeaturesCollection.push(feature.geometry.coordinates);
								circle++;
							}
						});

						childrenFeaturesCollection.push(childrenFeaturesCollectionFirst);

						if (childrenFeaturesCollection.length >= 3) {
							let geojsonPolygon = {
								type: "Feature",
								properties: {},
								geometry: {
									type: "Polygon",
									coordinates: [childrenFeaturesCollection],
								},
							};

							let polygon = turf.polygon(geojsonPolygon.geometry.coordinates);
							feature.geometry = polygon.geometry;
							feature.properties.polygonFake = true;
						}

						// console.log("###################################################");
						// console.log(polygon.geometry);
						// console.log(feature);
						// console.log("###################################################");
					}
				});

				let hasPoints = response.features.filter((feature) => {
					return feature.geometry.type === "Point";
				});

				// console.log("###################################################");
				// // console.log(response);
				// console.log("###################################################");

				setMapData(response);

				lotProperties.properties.points = hasPoints.length;
				dataMapContext.setLotProperties(lotProperties);

				// console.log("hasPoints", hasPoints.length);

				if (hasPoints.length !== 0) {
					getSpecies();
				} else {
					setShowOffSplash(true);
				}

				//
			})
			.catch((err) => {
				console.log("err getData", err);

				let splashConfig = {
					type: CONFIGS.splash.types.Error,
					title: t("splash.error.title"),
					body: t("splash.error.content.dataNotFound"),
					timer: false,
				};
				splashContext.openSplash(splashConfig);
			});

		//
	}

	// get lot species to compose the asset popup profile
	async function getSpecies() {
		//
		let fetchURL =
			API_URL + "/api/v3/maps/" + slugClient + "/" + slugLot + "/species";

		await load(fetchURL, JSONLoader)
			.then((resp) => {
				let data = resp;
				setSpeciesData(data);
			})
			.catch((err) => {
				console.error("Could not load data from species", err);

				let splashConfig = {
					type: CONFIGS.splash.types.Alert,
					title: t("splash.error.title"),
					body: t("splash.error.content.dataNotFoundSpecies"),
					timer: 1000,
				};
				splashContext.openSplash(splashConfig);
			});

		//
	}

	// get lot inspections
	async function getInspections() {
		//
		let fetchURL =
			API_URL + "/api/v3/maps/" + slugClient + "/" + slugLot + "/inspections";

		if (dataMapContext.timeSeries.start && dataMapContext.timeSeries.end) {
			fetchURL +=
				"?start=" +
				dataMapContext.timeSeries.start +
				"&end=" +
				dataMapContext.timeSeries.end;
		}

		await load(fetchURL, JSONLoader)
			.then((resp) => {
				let data = resp;

				setInspectionsData(data);
				dataMapContext.setInspectionsData(data);
			})
			.catch((err) => {
				console.error("No Inspections Data", err);
			})
			.finally(() => {
				return;
			});

		//
	}

	// close add splash screen
	async function adClose() {
		console.log("CLOSE AD");

		await new Promise(() =>
			setTimeout(function () {
				console.log("ad is closed");
				setAdShow(false);
			}, CONFIGS.splash.timer.ad)
		);
	}

	async function adOpen() {
		console.log("OPEN AD");
		setAdShow(true);
	}

	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////
	// all useeffects

	// get data first mount
	useEffect(() => {
		let splashConfig = {
			type: CONFIGS.splash.types.Loading,
			title: t("splash.loading.title"),
			body: t("splash.loading.body"),
			timer: false,
		};
		splashContext.openSplash(splashConfig);

		if (calledOnce.current) {
			return;
		}

		if (mapData == null) {
			getData();
			calledOnce.current = true;
		}

		// eslint-disable-next-line
	}, []);

	// check states updates
	useEffect(() => {
		let timerAdShow = 0.7;

		if (mapData && dataMapContext.dataLotProperties && forestMap) {
			forestMap.fitBounds(dataMapContext.dataLotProperties.boundBox, {
				center: dataMapContext.dataLotProperties.centerOfMass,
				padding: 40,
				zoom: 18.125,
				speed: 0.125,
				curve: 1,
				pitch: 72.5,
				duration: 7500,
				essential: true,

				easing(t) {
					if (t > timerAdShow && t < timerAdShow + 0.1) setAdShow(true);
					return 1 - Math.pow(1 - t, 5);
				},
			});

			// polygonToken for agroAPI
			let lotTempData = mapData.features.filter((feature) => {
				return (
					feature.properties.lot === true && feature.properties.children === false
				);
			});

			lotTempData = lotTempData[0];

			//////////////////////////////////////////////////////////////////////////////

			let hasAdInfo = lotTempData.properties.ad?.picture
				? lotTempData.properties.ad
				: null;

			console.log("hasAdInfo", lotTempData.properties.ad?.picture);

			setAdData(hasAdInfo);

			//////////////////////////////////////////////////////////////////////////////
			// childrens

			// markers of children
			if (mapData) {
				const dataChildrens = mapData.features.map((children) => {
					if (children.properties?.children === true) {
						if (children.geometry?.coordinates.length > 0) {
							return (
								<PopUpChildren
									key={children.properties?.cod_allotment}
									type={"children"}
									data={children}
								/>
							);
						}
					}
				});

				setMapDataChildrens(dataChildrens);
			}

			//////////////////////////////////////////////////////////////////////////////

			const dataLotPopup = {
				class: "lot",
				id: "lot",
				apiPermissions: lotTempData.properties.apiPermissions,

				latitude: dataMapContext.dataLotProperties.centerOfMass[1],
				longitude: dataMapContext.dataLotProperties.centerOfMass[0],

				title: lotTempData.properties.title,
				subtitle: lotTempData.properties.company,
				description: lotTempData.properties.description,
				image: lotTempData?.properties.logo,
			};

			setLotDataPopup(dataLotPopup);
			setShowPopupLot(true);

			//
		}
		// eslint-disable-next-line
	}, [mapData, dataMapContext.dataLotProperties]);

	// check permissions
	useEffect(() => {
		if (dataMapContext.apiPermissions) {
			// show 3d buildings
			setShowBuildings(
				dataMapContext.getLotPermission(CONFIGS.apiPermissions.api3DBuilding.code)
			);

			// show fire detection
			setShowFireDetection(
				dataMapContext.getLotPermission(CONFIGS.apiPermissions.apiFire.code)
			);
		}
		// eslint-disable-next-line
	}, [dataMapContext.apiPermissions]);

	// load species
	useEffect(() => {
		if (speciesData) {
			speciesDataTrees.current = speciesData;
			console.log("speciesDataTrees changed");
			setShowOffSplash(true);
		}
		// eslint-disable-next-line
	}, [speciesData]);

	// load inspections
	useEffect(() => {
		if (Object.keys(dataMapContext.timeSeries).length === 0) return;
		async function loadInspections() {
			await getInspections();
		}

		loadInspections();

		// eslint-disable-next-line
	}, [dataMapContext.timeSeries]);

	// sattelite tile updates
	useEffect(() => {
		setMapRasterUrl(null);

		if (dataMapContext.timeSeriesSelected && dataMapContext.rasterLayerIndex) {
			let selectedTile = dataMapContext.timeSeriesSelected.tile;

			console.log("selectedTile", selectedTile);

			console.log(
				"dataMapContext.rasterLayerIndex",
				dataMapContext.rasterLayerIndex
			);

			if (dataMapContext.rasterLayerIndex) {
				// return the same layer index
				let hasSelectedLayer = Object.keys(selectedTile).filter((tile) => {
					return tile === dataMapContext.rasterLayerIndex;
				});

				// if has the sale selected layer
				if (hasSelectedLayer.length !== 0) {
					let rasterSelected = selectedTile[dataMapContext.rasterLayerIndex];

					console.log("rasterSelected", rasterSelected);

					rasterSelected = rasterSelected.replace("http://", "https://");

					if (dataMapContext.rasterLayerIndex === "ndvi") {
						rasterSelected = rasterSelected + "&paletteid=4";
					}

					console.log(rasterSelected);

					setTimeout(function () {
						console.log("UPDATE RASTER SELECTED");
						setMapRasterUrl([rasterSelected]);
					}, 150);
				}

				//
			}
		}

		// eslint-disable-next-line
	}, [dataMapContext.rasterLayerIndex, dataMapContext.timeSeriesSelected]);

	// fire data
	useEffect(() => {
		if (dataMapContext.dataLotFire) {
			setMapDataFire(dataMapContext.dataLotFire);
		}
		// eslint-disable-next-line
	}, [dataMapContext.dataLotFire]);

	// close ad
	useEffect(() => {
		if (adShow === true) {
			console.log("ad showwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww");
			adClose();
		}
		// eslint-disable-next-line
	}, [adShow]);

	// ad infos
	useEffect(() => {
		if (adData) console.log("ad dataaaaaaa", adData);
		// eslint-disable-next-line
	}, [adData]);

	// close splash
	useEffect(() => {
		if (showOffSplash) splashContext.closeSplash();
		// eslint-disable-next-line
	}, [showOffSplash]);

	// log time series
	useEffect(() => {
		console.log(
			"dataMapContext.timeSeriesSelected",
			dataMapContext.timeSeriesSelected
		);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (mapData) {
			let tokenSearch = dataMapContext.openSearchToken;
			if (Number(tokenSearch)) {
				mapData.features.map((children) => {
					if (children.properties?.number === Number(tokenSearch)) {
						if (children.geometry?.coordinates.length > 0) {
							console.log(children);

							forestMap.fitBounds(dataMapContext.dataLotProperties.boundBox, {
								center: [
									children.geometry.coordinates[0],
									children.geometry.coordinates[1],
								],
								padding: 40,
								zoom: 20,
								speed: 0.125,
								curve: 2,
								pitch: 57.5,
								duration: 6500,
								essential: true,

								easing(t) {
									setAdShow(false);
									return 1 - Math.pow(1 - t, 5);
								},
							});
							let itemTemp = ["features"];
							itemTemp["features"] = [];
							itemTemp["features"].push(children);

							console.log(itemTemp);
							displayAssetInfo("click", itemTemp);

							dataMapContext.setOpenSearchToken(null);
							dataMapContext.setOpenSearch(false);
						}
					}
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataMapContext.openSearchToken]);

	// transform data deforestation
	useEffect(() => {
		if (dataMapContext.deforestationData.length > 0) {
			let deforestationTempData = {
				type: "FeatureCollection",
				features: dataMapContext.deforestationData,
			};
			setDeforestationData(deforestationTempData);
		}
		// eslint-disable-next-line
	}, [dataMapContext.deforestationData]);

	///////////////////////////////////////////////////////////////

	// get current user location
	useEffect(() => {
		if (userLocation.latitude && userLocation.longitude) {
			console.log("User location is available", userLocation);

			let locationGeoJson = {
				type: "Feature",
				properties: {
					accuracy: userLocation.accuracy,
					speed: userLocation.speed,
					heading: userLocation.heading,
					timestamp: userLocation.timestamp,
				},
				geometry: {
					type: "Point",
					coordinates: [userLocation.longitude, userLocation.latitude],
				},
			};

			setUserLocationGeoJson(locationGeoJson);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userLocation.latitude, userLocation.longitude]);

	///////////////////////////////////////////////////////////////

	const data = useMemo(() => {
		return mapData;
	}, [mapData]);

	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////

	const [scaleParam, setScaleParam] = useState();

	useEffect(() => {
		if (!scaleParam) return;
		scalePopup(scaleParam);
		// eslint-disable-next-line
	}, [scaleParam, showPopupTree]);

	const onMapLoad = useCallback((evt) => {
		const map = evt.target;

		///////////////////////////////////////////////////////////////
		// import icons

		for (let key in svgIconsMapList) {
			const svg = svgIconsMapList[key];
			console.log(svg);

			const customIcon = new Image(128, 128);
			customIcon.onload = () => map.addImage(svg.name, customIcon);
			customIcon.src = svg.src;
		}

		///////////////////////////////////////////////////////////////

		map.setFog({
			range: [18, 20],
			color: "rgb(255, 255, 255)", // lower atmosphere
			"high-color": "rgb(33, 133, 255)", // upper atmosphere
			"horizon-blend": 0.15, // Exaggerate atmosphere (default is .1)
			"space-color": "rgb(22, 22, 22)", // Background color
			"star-intensity": 0.2,
		});

		// map.on("style.load", () => {
		// 	map.setConfigProperty("basemap", "lightPreset", "dusk");
		// });

		map.on("pitch", function () {
			var ratio = map.getPitch() / 60;
			map
				.setLayoutProperty(pointsLineLayer.id, "text-size", ratio * 16)
				.setLayoutProperty(pointsLabelLayer.id, "text-offset", [0, -3 * ratio]);
		});

		const layersClickable = [
			layerPointsName,
			"points_label",
			"points_line",
			"deforestation_fill_layer",
			"tag_fill_layer",
		]; //,

		map.on("click", layersClickable, (e) => {
			displayAssetInfo("click", e);
		});

		map.on("mouseenter", layersClickable, () => {
			map.getCanvas().style.cursor = "pointer";
		});

		map.on("mouseleave", layersClickable, () => {
			map.getCanvas().style.cursor = "";
		});

		map.on("mousemove", (e) => {
			let mousePosition = e.lngLat;
			dataMapContext.setMousePosition(mousePosition);
		});

		// add the DEM source as a terrain layer
		map.addSource("mapbox-dem", {
			type: "raster-dem",
			url: "mapbox://mapbox.mapbox-terrain-dem-v1",
			tileSize: 512,
			maxzoom: 14,
		});
		map.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });

		/////////////////////////////////////////////////////////////////
		/////////////////////////////////////////////////////////////////

		const zoomChange = 12.25;
		let lastZoom = map.getZoom();
		let currentZoom;

		// const zoomChangePopupTree = 18.25;

		map.on("zoom", () => {
			//
			currentZoom = map.getZoom();

			///////////////////////////////////////////////////////////////
			// working with map projection change based on zoom

			const currentProjection = map.getProjection();

			if (currentZoom > zoomChange) {
				if (currentProjection.name === "globe") {
					console.log("set mercator");

					map.setProjection({
						name: "mercator",
					});
					setMapMaxPitch(80);
				}
			}

			if (currentZoom < zoomChange) {
				if (currentZoom < 6) {
					if (viewState.maxPitch > 0) setMapMaxPitch(0);
				}

				if (currentProjection.name !== "globe") {
					console.log("set globe");

					map.setProjection({
						name: "globe",
						center: [viewState.latitude, viewState.longitude],
					});
				}
			}

			// let showPopups =
			// 	currentZoom > zoomChange - 0.5
			// 		? setShowPopupAtAll(true)
			// 		: setShowPopupAtAll(false) && setShowPopupTree(false);

			///////////////////////////////////////////////////////////////
			// proportional zoom of objects

			const dynamicScale = (1 + (currentZoom - 12) * 0.5) / 1.5;

			setScaleParam(dynamicScale);
			// scalePopup(dynamicScale);

			///////////////////////////////////////////////////////////////

			lastZoom = currentZoom;
			//
		});

		// map.addLayer({
		// 	id: "rpd_parks",
		// 	type: "raster",
		// 	source: {
		// 		type: "raster",
		// 		url: "http://terrabrasilis.dpi.inpe.br/geoserver/prodes-brasil-nb/wms?service=WMS",
		// 	},
		// 	"source-layer": "prodes_brasil",
		// });

		// eslint-disable-next-line
	}, []);

	function scalePopup(dynamicScale) {
		const boxes = document.querySelectorAll([
			"div.lot.mapboxgl-popup",
			"div.children.mapboxgl-popup",
			"div.item.mapboxgl-popup",
		]);

		boxes.forEach((box) => {
			const content = box.querySelector(".mapboxgl-popup-content");
			content.style.transform = `scale(${dynamicScale})`;
			content.classList.add("mapboxgl-popup-content-scaled");

			const tip = box.querySelector(".mapboxgl-popup-tip");
			tip.style.transform = `scale(${dynamicScale * 1.125})`;

			const tipShadow = box.querySelector(".mapboxgl-popup-tip");
			tipShadow.classList.add("mapboxgl-popup-tip-scaled");

			if (dynamicScale < 0) {
				content.classList.add("mapboxgl-popup-hidden");
				tip.classList.add("mapboxgl-popup-hidden");
				tipShadow.classList.add("mapboxgl-popup-hidden");
			} else {
				content.classList.remove("mapboxgl-popup-hidden");
				tip.classList.remove("mapboxgl-popup-hidden");
				tipShadow.classList.remove("mapboxgl-popup-hidden");
			}
		});
	}

	///////////////////////////////////////////////////////////////

	function displayAssetInfo(type, e) {
		//
		setShowPopupTree(false);

		const feature = e.features[0];
		console.log("feature E", feature);

		const featureProperties = feature.properties;
		console.log("displayAssetInfo", featureProperties);

		const isCluster = featureProperties.cluster ? true : false;
		const isPolygon = featureProperties.polygon ? true : false;
		const geometry = feature.geometry;
		const coordinates =
			geometry.type === "Point"
				? geometry.coordinates.slice()
				: [e.lngLat.lng, e.lngLat.lat];

		if (!isCluster && type === "click" && !isPolygon) {
			//
			const number = Number(feature.properties.number);

			const tree_cod = Number(e.features[0].properties.tree_cod);
			const tree_category = e.features[0].properties.category;

			const tempSpecies = speciesDataTrees.current;
			const tempData = tempSpecies.filter((el) => el.cod_tree === tree_cod);

			let assetDescription;
			if (tree_category !== "culture") {
				assetDescription =
					tempData[0].name_scientific + " (" + tempData[0].family + ")";
			} else {
				if (featureProperties?.area) {
					featureProperties.area = JSON.parse(featureProperties?.area);
					assetDescription =
						"Area: " +
						featureProperties?.area.size +
						" " +
						featureProperties?.area.type;
				}
			}

			const data = {
				class: "item",
				id: tree_cod,

				longitude: coordinates[0],
				latitude: coordinates[1],

				title: tempData[0].name_public,
				subtitle: number,
				description: assetDescription,
				image: tempData[0].medias?.url,
			};

			setViewPopupTree(data);
			setShowPopupTree(true);
		}

		if (!isCluster && isPolygon && type === "click") {
			//
			const publishedImages = JSON.parse(featureProperties.publishedImages);
			const satelliteSource = JSON.parse(featureProperties.sources);
			const deforestationClasses = JSON.parse(
				featureProperties.deforestationClasses
			);
			const crossedBiomes = JSON.parse(featureProperties.crossedBiomes);
			const crossedSpecialTerritories = JSON.parse(
				featureProperties.crossedSpecialTerritories
			);
			const imageAcquiredAfterList = JSON.parse(
				featureProperties.imageAcquiredAfterList
			);
			const imageAcquiredBeboreList = JSON.parse(
				featureProperties.imageAcquiredBeboreList
			);

			const data = {
				class: "deforestation",
				id: featureProperties.alertCode,

				latitude: e.lngLat.lat,
				longitude: e.lngLat.lng,
				polygon: featureProperties.polygon,

				areaHa: featureProperties.areaHa,
				date: imageAcquiredAfterList.acquired_at,
				constellation: imageAcquiredAfterList.constellation,
				source: satelliteSource,

				images: publishedImages,
				crossedBiomes: crossedBiomes,
				deforestationClasses: deforestationClasses,
				crossedSpecialTerritories: crossedSpecialTerritories,

				imageAcquiredBeboreList: imageAcquiredBeboreList,
				imageAcquiredAfterList: imageAcquiredAfterList,
			};

			// console.log("data", data);

			setViewPopupTree(data);
			setShowPopupTree(true);
		}
		//
	}

	///////////////////////////////////////////////////////////////

	return (
		<>
			<MapReact
				{...viewState}
				id="forestMap"
				mapboxAccessToken={MAPBOX_TOKEN}
				mapRef={forestMap}
				mapStyle={"mapbox://styles/mapbox/satellite-v9"}
				onMove={(evt) => setViewState(evt.viewState)}
				onLoad={onMapLoad}
				icon-allow-overlap={true}
				attributionControl={false}
				customAttribution={false}
				antialias={true}
				maxPitch={mapMaxPitch}
				maxZoom={22}
				boxZoom={true}
				onBoxZoomEnd={(e) => {
					console.log("onBoxZoomEnd", e.originalEvent);

					const event = e.originalEvent;

					// const canvas = forestMap.getCanvasContainer();

					// const rect = canvas.getBoundingClientRect();
					// console.log('rect', rect);

					const width = event.offsetX;
					const height = event.offsetY;
					const features = forestMap.queryRenderedFeatures(
						[
							[event.x - width / 2, event.y - height / 2],
							[event.x + width / 2, event.y + height / 2],
						],
						{ layers: ["points"] }
					);

					let allTagsSelected = [];
					features.forEach((feature) => {
						let tagNumber = feature._vectorTileFeature.properties?.number;
						if (!allTagsSelected.includes(tagNumber)) allTagsSelected.push(tagNumber);
					});

					console.log(
						"Selected tags count: " + allTagsSelected.length + "",
						allTagsSelected
					);
				}}

				// mapStyle={
				// 	"mapbox://styles/mapbox/satellite-v9"
				// 	// showBuildings
				// 	//   ? // ? 'mapbox://styles/mapbox/satellite-streets-v12'
				// 	//     'mapbox://styles/mapbox/streets-v12'
				// 	//   : 'mapbox://styles/mapbox/satellite-v9'
				// }
				// onBoxZoomStart={(e) => {
				//   console.log('onBoxZoomStart', e);
				// }}
			>
				{/*  */}

				{/* Lot CHILDRENS Popups */}
				{mapDataChildrens}

				{/* POPUP LOT */}
				{showPopupAtAll && showPopupLot && lotDataPopup && (
					<PopUp
						type={"lot"}
						data={lotDataPopup}
						setShowPopup={setShowPopupLot}
						openAd={adOpen}
					/>
				)}

				{/* POPUP TREE */}
				{showPopupAtAll && showPopupTree && (
					<PopUp
						type={"item"}
						data={viewPopupTree}
						setShowPopup={setShowPopupTree}
					/>
				)}

				{/* DATA - SATELLITE */}
				{dataMapContext.dataImagery &&
				dataMapContext.dataLotProperties.boundBox &&
				dataMapContext.timeSeriesSelected &&
				dataMapContext.dataLotProperties.polygonAgroAPIToken &&
				mapRasterUrl ? (
					<>
						<Source
							id="satelliteImagery"
							type="raster"
							tiles={mapRasterUrl}
							tileSize={256}
							bounds={dataMapContext.dataLotProperties.boundBox}>
							<Layer
								id="satelliteImageryLayer"
								type="raster"
								minzoom={0}
								maxzoom={22}
								// source={"satelliteImagery"}
								paint={{
									"raster-opacity": dataMapContext.rasterLayerOpacity,
									"raster-resampling": "linear",
									"raster-fade-duration": 500,
								}}
							/>
						</Source>
					</>
				) : null}

				{/* 3D BUILDINGS + Water + Places Labels */}
				<Source
					id="mapbox-streets"
					type="vector"
					url="mapbox://mapbox.mapbox-streets-v8">
					{showBuildings && (
						<>
							<Layer {...mapboxBuildings3dLayer} />
						</>
					)}
					<Layer {...mapboxWaterBodiesLayer} />
					<Layer {...mapboxPlacesLayer} />
					<Layer {...mapboxAdminLayer} />
				</Source>

				{/* ************ SENTINEL HUB */}
				{/* <Source {...wmtsSentinelSource}>
					<Layer {...wmtsSentinelLayer} />
				</Source> */}

				{/* DATA FIRE NASA */}
				{mapDataFire && (
					<Source id="fireData" type="geojson" data={mapDataFire}>
						<Layer {...pointsFireLayer} />
						{/* <Layer {...polygonLotFireLineLayer} /> */}
					</Source>
				)}

				{/* NASA GIBS */}
				{/* <Source {...wmtsGibsSource}> */}
				{/* <Layer {...wmtsGibsLayer} /> */}
				{/* beforeId="gibs-layer-vector" */}
				{/* </Source> */}

				{/* 
				<Source {...wmtsGibsSourceVector}>
					<Layer {...wmtsGibsLayerVector} />
				</Source> */}

				{/* DATA - POLYGONS */}
				<Source id="polygons" type="geojson" data={data}>
					<Layer {...polygonLotLineLayer} />
					<Layer {...polygonLotChildrenLineLayer} />
					<Layer {...polygonLotFillLayer} />

					<Layer {...polygonTagLineLayer} />
					<Layer {...polygonTagFillLayer} />
					{/* <Layer {...polygonTagPatternLayer} /> */}

					{/* <Layer {...polygonTagFillExtrusionLayer} /> */}
				</Source>

				{/* DATA - INSPECTIONS */}
				{inspectionsData && (
					<Source
						id="inspections"
						type="geojson"
						data={inspectionsData}
						cluster={false}>
						{/* <Layer {...pointsInspectionLineLayer} /> */}
						{/* <Layer {...pointsInspectionLabelLayer} /> */}
						<Layer {...pointsInspectionLayer} />
					</Source>
				)}

				{/* DATA - POINTS */}
				<Source
					type="geojson"
					id="dataApi"
					data={data}
					cluster={true}
					clusterRadius={50}
					clusterMinPoints={20}>
					{/* CLUSTER */}
					<Layer {...clusterLayer} />
					<Layer {...clusterCountLayer} />

					{/* POINTS */}
					{/* <Layer {...pointsLayerImage} /> */}
					<Layer {...pointsLayer} />
					<Layer {...pointsLabelLayer} />
					<Layer {...pointsLineLayer} />

					{/*  */}
				</Source>

				{/* DATA - DEFORESTATION */}
				<Source id="deforestation" type="geojson" data={deforestationData}>
					<Layer {...polygonDeforestationFillLayer} />
					<Layer {...polygonDeforestationLineLayer} />
				</Source>

				{/* DEVICE LOCATION */}
				{userLocation.latitude && userLocation.longitude && userLocationGeoJson && (
					<Source id="deviceLocation" type="geojson" data={userLocationGeoJson}>
						<Layer {...polygonDeviceFillLayer} />
					</Source>
				)}

				{/*  */}
			</MapReact>

			{adShow && adData ? <Ad ad={adData} /> : <></>}
			{adData ? <AdMini ad={adData} adOpen={adOpen} /> : <></>}

			<Container />
		</>
	);
}
