import iconInspection from "../assets/images/svg/iconMapInspection.svg";
import iconFire from "../assets/images/svg/iconMapFire.svg";
import iconBug from "../assets/images/svg/iconMapBug.svg";

export const svgIconsMapList = {
	inspection: {
		src: iconInspection,
		name: "icon-map-inspection",
	},
	fire: {
		src: iconFire,
		name: "icon-map-fire",
	},
	iconBug: {
		src: iconBug,
		name: "icon-map-bug",
	},
};
