import React, { useEffect, useState, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

import { CalendarDaysIcon } from "@heroicons/react/24/outline";

import { useSplash } from "../../contexts/splash";
import { useDataMap } from "../../contexts/datamap";

import { COLORS } from "../../assets/colors";
import { CONFIGS } from "../../assets/configs";

import { MapHeader, MapTime } from "../../assets/styles/map";
import { convertUnixToDate } from "../../services/converters";

import ForestWatch_Lettering_Gray from "../../assets/images/svg/ForestWatch_Lettering_Gray.svg";
import Button_Layers_Inactive from "../../assets/images/svg/Button_Layers_Inactive.svg";
import Button_Layers_Active from "../../assets/images/svg/Button_Layers_Active.svg";
import Button_Login_Inactive from "../../assets/images/svg/Button_Login_Inactive.svg";
import Button_Search_Active from "../../assets/images/svg/Button_Search_Active.svg";
import Button_Search_Inactive from "../../assets/images/svg/Button_Search_Inactive.svg";

registerLocale("ptBR", ptBR);

export default function Header(props) {
	//
	const { toggleMenu } = props;

	const { t } = useTranslation();

	const splashContext = useSplash();
	const dataMapContext = useDataMap();

	const [toggleLayers, setToggleLayers] = useState(false);
	const [toggleSearch, setToggleSearch] = useState(false);
	const [timeSeries, setTimeSeries] = useState(null);
	const [timeSeriesMap, setTimeSeriesMap] = useState(null);
	const [timeSeriesSelected, setTimeSeriesSelected] = useState(null);

	const [selectedMenu, setSelectedMenu] = useState(null);

	const [hasImagery, setHasImagery] = useState(null);

	const [timeSeriesDisplay, setTimeSeriesDisplay] = useState({
		start: null,
		end: null,
	});

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const onDateSelect = (dates) => {
		dataMapContext.getTimesSeries(dates);

		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
	};

	const CallDatePicker = forwardRef(({ value, onClick }, ref) => (
		<>
			<CalendarDaysIcon className={"iconDatePicker"} onClick={onClick} ref={ref} />
			{timeSeries && (
				<>{/* {DateTime.fromSeconds(timeSeries).toFormat('dd MMM')} */}</>
			)}
		</>
	));

	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////

	function handleToggleMenu() {
		if (toggleLayers) setToggleLayers(false);
		if (!toggleLayers) setToggleLayers(true);
		dataMapContext.setMenuActive(null);
	}

	function handleSearch() {
		if (toggleSearch) {
			setToggleSearch(false);
			dataMapContext.setOpenSearch(false);
		}
		if (!toggleSearch) {
			setToggleSearch(true);
			dataMapContext.setOpenSearch(true);
		}
		console.log("handleSearch");
	}

	function handleLogIn() {
		window.location.href = "https://app.forest.watch";
	}

	function handleTimeSeries(e) {
		dataMapContext.selecTimeSeries(e.currentTarget.dataset.serie);
	}

	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////

	useEffect(() => {
		if (dataMapContext.dataImagery) {
			setHasImagery(true);
			setTimeSeriesMap(dataMapContext.dataImagery);
		}
	}, [dataMapContext.dataImagery]);

	useEffect(() => {
		toggleMenu(toggleLayers);
		// eslint-disable-next-line
	}, [toggleLayers]);

	useEffect(() => {
		if (dataMapContext.dataLotProperties?.polygonAgroAPIToken?.length > 0)
			setHasImagery(true);
		// eslint-disable-next-line
	}, [dataMapContext.dataLotProperties]);

	// timeSeries changed
	useEffect(() => {
		setTimeSeries(dataMapContext.timeSeries);

		// if (dataMapContext.timeSeries?.start && dataMapContext.timeSeries?.end) {
		if (timeSeries) {
			setTimeSeriesDisplay({
				start: convertUnixToDate(timeSeries.start, true),
				end: convertUnixToDate(timeSeries.end, true),
			});
		}
		// eslint-disable-next-line
	}, [dataMapContext.timeSeries, timeSeries]);

	useEffect(() => {
		console.log(
			"dataMapContext.timeSeriesSelectedIndex",
			dataMapContext.timeSeriesSelectedIndex
		);
		setTimeSeriesSelected(dataMapContext.timeSeriesSelectedIndex);
		// eslint-disable-next-line
	}, [dataMapContext.timeSeriesSelectedIndex]);

	useEffect(() => {
		setSelectedMenu(dataMapContext.menuActive);
	}, [dataMapContext.menuActive]);

	useEffect(() => {
		setToggleSearch(dataMapContext.openSearch);
	}, [dataMapContext.openSearch]);

	return (
		<MapHeader>
			<div className="flex-items-sides">
				<img src={ForestWatch_Lettering_Gray} alt="Forest Watch" />
			</div>

			<div className="flex-items-center">
				{
					// (selectedMenu === CONFIGS.apiPermissions.apiSatellite.code && hasImagery)
					timeSeriesDisplay && (
						<MapTime>
							<div className="timeSeriesContainer">
								<div className="timeSeriesLeft">
									<div className="datePicker">
										<DatePicker
											calendarStartDay={1}
											selected={new Date()}
											maxDate={new Date()}
											onChange={onDateSelect}
											startDate={startDate}
											dateFormat="Pp"
											customInput={<CallDatePicker />}
											selectsRange
											locale="ptBR"
											monthsShown={4}
											// showMonthYearPicker
											// showFullMonthYearPicker
											// withPortal
										/>
									</div>

									<div className="timeSelected">
										<p>{timeSeriesDisplay.start}</p>
										<p>{timeSeriesDisplay.end}</p>
									</div>
								</div>

								<div className="timeSeriesCenter">
									{timeSeriesMap && (
										<>
											<ol>
												{timeSeriesMap.map((time, index) => {
													let selectedClass =
														parseInt(timeSeriesSelected) === parseInt(index)
															? "selected"
															: "";
													return (
														<li
															key={index}
															data-serie={index}
															className={selectedClass}
															onClick={(e) => {
																handleTimeSeries(e);
															}}>
															<div className={selectedClass}>
																{DateTime.fromSeconds(time.dt).toFormat("dd MMM")}
															</div>
														</li>
													);
												})}

												{Object.keys(timeSeriesMap).length === 0 && (
													<div className="noImages">
														Sem imagens para o período selecionado
													</div>
												)}
											</ol>
										</>
									)}
								</div>

								<div className="timeSeriesRight"></div>
							</div>
						</MapTime>
					)
				}
			</div>

			<div className="flex-items-sides right">
				<button onClick={handleLogIn}>
					<img src={Button_Login_Inactive} alt="Sign In" />
				</button>

				<button onClick={handleSearch}>
					<img
						src={toggleSearch ? Button_Search_Active : Button_Search_Inactive}
						alt="Buscar"
					/>
				</button>

				<button onClick={handleToggleMenu}>
					<img
						src={toggleLayers ? Button_Layers_Active : Button_Layers_Inactive}
						alt="Layers"
					/>
				</button>
			</div>
		</MapHeader>
	);
}
