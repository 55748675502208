import React from "react";
import { MapProvider } from "react-map-gl";

import { DataMapProvider } from "../contexts/datamap";

import { ContainerApp } from "../assets/styles/elements";
import { COLORS } from "../assets/colors";

import Map from "./map";

export default function RootMap() {
	return (
		<ContainerApp className={COLORS.gray_superlight}>
			<DataMapProvider>
				<MapProvider>
					<Map />
				</MapProvider>
			</DataMapProvider>
		</ContainerApp>
	);
}
