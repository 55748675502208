import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDataMap } from "../../contexts/datamap";

import Menu from "./menu";
import Header from "./header";
import Status from "./status";
import Search from "./search";
import Footer from "./footer";

export default function Container() {
	//
	const { t } = useTranslation();
	const dataMapContext = useDataMap();

	const [showMenu, setShowMenu] = useState(false);
	const [showSearch, setShowSearch] = useState(false);

	function toggleMenu(show) {
		setShowMenu(show);
	}

	useEffect(() => {
		setShowSearch(dataMapContext.openSearch);
	}, [dataMapContext.openSearch]);

	return (
		<>
			<Header toggleMenu={toggleMenu} />
			{showSearch && <Search />}
			{showMenu && <Menu />}
			{/* <Status /> */}
			<Footer />
		</>
	);
}
