import { COLORS } from "../../assets/colors";
import { svgIconsMapList } from "../../services/svgtomap";

const layerMinZoom = 18.25;
export const layerSource = "api";
const layerSatellite = "satelliteImageryLayer";
export const layerPointsName = "points_assets";

/////////////////////////////////////////////////////////////////
// CLUSTERS
/////////////////////////////////////////////////////////////////

export const clusterLayer = {
	id: "points_clustered",
	type: "circle",
	source: layerSource,
	filter: ["has", "point_count"],

	paint: {
		"circle-radius": [
			"step",
			["get", "point_count"],
			25,
			100,
			35,
			750,
			45,
			1000,
			75,
		],
		"circle-color": COLORS.gray_mid,
	},
};

export const clusterCountLayer = {
	id: "points_clustered_count",
	type: "symbol",
	source: layerSource,
	filter: ["has", "point_count"],

	paint: { "text-color": COLORS.white },
	layout: {
		"text-field": "{point_count_abbreviated}",
		"text-font": ["DIN Offc Pro Medium", "Roboto Black", "Arial Unicode MS Bold"],
		"text-size": 16,
	},
};

/////////////////////////////////////////////////////////////////
// POINTS
/////////////////////////////////////////////////////////////////

const patternSelected = COLORS.pattern1b;
const patternFire = COLORS.pattern14;

const patternOpacity = 0.75;
const pointsMinZoom = 10;

export const pointsLayerImage = {
	id: layerPointsName,
	type: "symbol",
	source: layerSource,
	filter: ["all", ["!has", "point_count"], ["==", "$type", "Point"]],

	layout: {
		"icon-image": ["concat", "color0", ["get", "tree_type"]], // reference the image
		"icon-size": 0.05,
	},
};

export const pointsLayer = {
	id: layerPointsName,
	type: "circle",
	source: layerSource,
	filter: ["all", ["!has", "point_count"], ["==", "$type", "Point"]],

	paint: {
		"circle-radius": {
			base: 4,
			stops: [
				// [12, 5],
				[16, 8],
				[18, 12],
				[20, 15],
				[22, 20],
			],
		},
		"circle-opacity": patternOpacity,
		"circle-color": [
			"match",
			["get", "tree_type"],
			0,
			patternSelected.color00,
			1,
			patternSelected.color01,
			2,
			patternSelected.color02,
			3,
			patternSelected.color03,
			4,
			patternSelected.color04,
			5,
			patternSelected.color05,
			patternSelected.colorNull,
		],
		// "circle-color": [
		// 	"case",
		// 	[
		// 		"match",
		// 		["get", "tree_type"],
		// 		0,
		// 		patternSelected.color00,
		// 		1,
		// 		patternSelected.color01,
		// 		2,
		// 		patternSelected.color02,
		// 		3,
		// 		patternSelected.color03,
		// 		4,
		// 		patternSelected.color04,
		// 		5,
		// 		patternSelected.color05,
		// 		patternSelected.colorNull,
		// 	],
		// 	[["has", "cal"]],
		// ],
		"circle-pitch-alignment": "viewport",
		"circle-translate-anchor": "viewport",
		"circle-translate": [0, 0],
		// "circle-pitch-alignment": "map",
		// "circle-translate": [0, -10],
	},
	minzoom: pointsMinZoom,
	layout: {},
};

export const pointsLineLayer = {
	id: "points_line",
	type: "symbol",
	source: layerSource,
	filter: ["all", ["!has", "point_count"], ["==", "$type", "Point"]],

	paint: { "text-color": COLORS.gray_mid },
	layout: {
		"text-font": ["Source Code Pro Medium", "Arial Unicode MS Regular"],
		"text-field": "-----",
		"text-letter-spacing": -1,
		"text-rotate": 90,
		"text-anchor": "left",
		"text-max-width": 20,
		"text-justify": "left",
		"text-allow-overlap": true,
		"text-offset": [0, 0],
		// "icon-image": ["concat", ["get", "number"], "-15"],
	},
	minzoom: layerMinZoom - 0.5,
};

export const pointsLabelLayer = {
	id: "points_label",
	type: "symbol",
	source: layerSource,
	filter: ["all", ["!has", "point_count"], ["==", "$type", "Point"]],
	paint: {
		"text-color": COLORS.gray_dark,
		"text-halo-color": COLORS.gray_light,
		"text-halo-width": 0,
	},
	layout: {
		"text-size": 20,
		"text-field": ["get", "number"],
		"text-allow-overlap": true,
		"text-offset": [0, 0],
		// "icon-image": ["concat", ["get", "number"], "-15"],
	},
	minzoom: layerMinZoom,
};

export const pointsFireInpeLayer = {
	id: "inpe-fires-risk",
	type: "raster",
	// filter: ['!has', 'buffer'],
};

export const pointsFireLayer = {
	id: "fires",
	type: "circle",
	source: "fireData",
	paint: {
		"circle-radius": [
			"interpolate",
			["exponential", 1.5], // Set the exponential rate of change to 0.5
			["get", "frp"],
			2,
			6,

			3,
			9,

			4,
			12,

			6,
			18,

			7,
			24,

			8,
			33,
		],
		"circle-opacity": 1,
		"circle-color": [
			"interpolate",
			["exponential", 1], // Set the exponential rate of change to 0.5
			["get", "frp"],
			1,
			patternFire.color01,
			2,
			patternFire.color02,
			3,
			patternFire.color03,
			4,
			patternFire.color04,
			6,
			patternFire.color05,
			7,
			patternFire.color06,
			8,
			patternFire.color07,
			9,
			patternFire.color08,
		],
		"circle-pitch-alignment": "viewport",
		"circle-translate": [0, -10],
		"circle-translate-anchor": "viewport",
	},
	filter: ["!has", "buffer"],
	minzoom: pointsMinZoom,
};

export const pointsInspectionLayer = {
	id: "inspection_layer",
	type: "symbol",
	source: "inspections",
	layout: {
		"icon-image": svgIconsMapList.inspection.name, // reference the image
		"icon-size": 0.35,
	},
	filter: ["all", ["!has", "point_count"], ["==", "$type", "Point"]],
	// minzoom: pointsMinZoom * 1.25,
};

export const pointsInspectionLineLayer = {
	id: "inspection_line",
	type: "symbol",
	source: "inspections",
	filter: ["all", ["!has", "point_count"], ["==", "$type", "Point"]],

	paint: { "text-color": COLORS.gray_mid },
	layout: {
		"text-font": ["Source Code Pro Medium", "Arial Unicode MS Regular"],
		"text-field": "--------",
		"text-letter-spacing": -1,
		"text-rotate": 90,
		"text-anchor": "left",
		"text-max-width": 20,
		"text-justify": "left",
		"text-allow-overlap": true,
		"text-offset": [0, 0],
	},
	minzoom: layerMinZoom - 0.5,
};

export const pointsInspectionLabelLayer = {
	id: "inspection_label",
	type: "symbol",
	source: "inspections",
	filter: ["all", ["!has", "point_count"], ["==", "$type", "Point"]],
	paint: {
		"text-color": COLORS.gray_dark,
		"text-halo-color": COLORS.gray_light,
		"text-halo-width": 0,
	},
	layout: {
		"text-size": 20,
		"text-field": ["get", "cod_inspection"],
		"text-allow-overlap": true,
		"text-offset": [0, 0],
	},
	minzoom: layerMinZoom,
};
/////////////////////////////////////////////////////////////////
// POLYGON
/////////////////////////////////////////////////////////////////

export const polygonLotFireLineLayer = {
	id: "lot_fire_line",
	type: "line",
	source: "fireData",
	paint: {
		"line-color": patternFire.color00,
		// 'line-opacity': 0.25,
		"line-width": 1,
		"line-dasharray": [3, 1],
	},
	filter: ["has", "buffer"],
};

export const polygonLotChildrenLineLayer = {
	id: "lot_children_line",
	type: "line",
	source: "polygons",
	paint: {
		"line-color": COLORS.green_light,
		"line-opacity": 1,
		"line-width": 2,
		// 'line-dasharray': [2, 1],
	},
	// filter: ['has', 'children'],
	// filter: ["==", "children", true],
	filter: ["all", ["!=", "polygonFake", true], ["==", "children", true]],
};

export const polygonLotLineLayer = {
	id: "lot_line",
	type: "line",
	source: "polygons",
	paint: {
		"line-color": [
			"case",
			["boolean", ["all", ["has", "color"]], true],
			["get", "color"],
			// COLORS.gray_light,
			[
				"case",
				["boolean", ["get", "children"], true],
				COLORS.gray_light,
				COLORS.green_light,
			],
		],

		"line-width": ["case", ["boolean", ["get", "children"], true], 1.5, 2.5],
		// 'line-opacity': 0.75,
		// 'line-dasharray': [3, 6],
	},
	filter: ["all", ["has", "lot"], ["!=", "polygonFake", true]],
	// filter: ["has", "lot"],
};

export const polygonLotFillLayer = {
	id: "lot_fill",
	type: "fill",
	source: "polygons",
	paint: {
		"fill-opacity": 0.25,
		"fill-color": [
			"case",
			["boolean", ["all", ["has", "lot"], ["has", "color"]], true],
			["get", "color"],
			COLORS.gray_light,
		],
		// "fill-outline-color": COLORS.green_light,
	},
	// filter: ["has", "lot"],
	filter: ["all", ["has", "lot"], ["!=", "polygonFake", true]],
};

export const polygonTagFillLayer = {
	id: "tag_fill_layer",
	type: "fill",
	source: "polygons",
	paint: {
		"fill-opacity": 0.25,
		"fill-color": [
			"case",
			[
				"boolean",
				[
					"all",
					["has", "category"],
					["==", ["get", "category"], "culture"],
					["has", "color"],
				],
				true,
			],
			["get", "color"],
			COLORS.transparent,
		],
		// "fill-outline-color": COLORS.green_light,
	},
	filter: ["all", ["has", "number"], ["==", "$type", "Polygon"]],
};

export const polygonTagLineLayer = {
	id: "tag_fill_line_layer",
	type: "line",
	source: "polygons",
	paint: {
		"line-width": 2,
		"line-opacity": 1,
		"line-color": [
			"case",
			[
				"boolean",
				[
					"all",
					["has", "category"],
					["==", ["get", "category"], "culture"],
					["has", "color"],
				],
				true,
			],
			["get", "color"],
			COLORS.alert,
		],
	},
	filter: [
		"all",
		["has", "number"],
		["==", "$type", "Polygon"],
		["has", "color"],
	],
};

export const polygonTagFillExtrusionLayer = {
	id: "tag_fill_extrusion_layer",
	type: "fill-extrusion",
	source: "polygons",
	// minzoom: 15,
	paint: {
		"fill-extrusion-color": [
			"case",
			["boolean", ["has", "owner"], true],
			COLORS.green_light,
			COLORS.gray_light,
		],
		"fill-extrusion-height": ["get", "height"],
		"fill-extrusion-opacity": 0.75,
		"fill-extrusion-vertical-gradient": true,
	},
	filter: ["all", ["has", "number"], ["==", "$type", "Polygon"]],
};

export const polygonDeforestationFillLayer = {
	id: "deforestation_fill_layer",
	type: "fill",
	source: "deforestation",
	paint: {
		"fill-color": COLORS.error,
		"fill-opacity": 0.25,
	},
};

export const polygonDeforestationLineLayer = {
	id: "deforestation_line_layer",
	type: "line",
	source: "deforestation",
	paint: {
		"line-color": COLORS.error,
		// "line-opacity": 0.75,
		"line-width": 2.5,
	},
};

export const polygonDeviceFillLayer = {
	id: "device_fill",
	source: "deviceLocation",
	type: "circle",
	paint: {
		"circle-radius": {
			base: 4,
			stops: [
				[16, 8],
				[18, 12],
				[20, 15],
				[26, 20],
			],
		},
		"circle-opacity": 1,
		"circle-color": COLORS.device.center,
		"circle-stroke-color": COLORS.device.border,
		"circle-stroke-width": 8,

		"circle-translate": [0, 0],
		"circle-translate-anchor": "viewport",
		"circle-pitch-alignment": "viewport",
		"circle-pitch-scale": "viewport",
	},
	// minzoom: pointsMinZoom,
	layout: {},
};

/////////////////////////////////////////////////////////////////
// Satellite Imagery
/////////////////////////////////////////////////////////////////

export const satelliteImageryLayer = {
	id: "satellite",
	type: "raster",
	source: layerSatellite,

	minzoom: 0,
	maxzoom: 22,

	paint: {
		"raster-opacity": 0.5,
		"raster-resampling": "linear",
		"raster-fade-duration": 500,
	},
};

let buildings3dMinZoom = 11;

export const mapboxBuildings3dLayer = {
	id: "buildings-3d",
	source: "mapbox-streets",
	"source-layer": "building",
	filter: ["==", "extrude", "true"],
	// filter: [">", "height", 0],
	type: "fill-extrusion",
	minzoom: buildings3dMinZoom,
	paint: {
		"fill-extrusion-color": COLORS.gray_dark,
		"fill-extrusion-opacity": 0.95,

		// "fill-extrusion-height": ["get", "height"],
		// "fill-extrusion-base": ["get", "min_height"],

		"fill-extrusion-height": [
			"interpolate",
			["linear"],
			["zoom"],
			buildings3dMinZoom,
			0,
			buildings3dMinZoom + 0.25,
			["get", "height"],
		],
		"fill-extrusion-base": [
			"interpolate",
			["linear"],
			["zoom"],
			buildings3dMinZoom,
			0,
			buildings3dMinZoom + 0.25,
			["get", "min_height"],
		],
	},
};

export const mapboxWaterBodiesLayer = {
	id: "water-bodies",
	source: "mapbox-streets",
	"source-layer": "waterway",
	type: "line",
	minzoom: 0,
	paint: {
		"line-color": COLORS.petrol,
		"line-width": 2.5,
	},
};

export const mapboxPlacesLayer = {
	id: "places",
	source: "mapbox-streets",
	"source-layer": "place_label",
	type: "symbol",
	minzoom: 3,
	filterrank: 1,
	sizerank: 5,
	class: "country",
	layout: {
		"text-size": 12.5,
		"text-field": ["get", "name"],
	},
	paint: {
		"text-color": COLORS.white,
		"text-halo-color": COLORS.backgroundContainers,
		"text-halo-width": 5,
	},

	tiles: {
		limit: [["lowest_where_in_distance", true, 256, "null"]],
	},
};

export const mapboxAdminLayer = {
	id: "admin",
	source: "mapbox-streets",
	"source-layer": "admin",
	type: "line",
	minzoom: 0,
	admin_level: 2,
	layout: {},
	paint: {
		"line-color": COLORS.sand,
		"line-width": 1,
		"line-dasharray": [3, 1],
		// 'line-join': 'round',
	},
};

export const wmtsLayerSource = {
	type: "raster",
	tiles: [
		"https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg",
	],
	tileSize: 256,
	attribution:
		'Map tiles by <a target="_top" rel="noopener" href="http://www.swisstopo.admin.ch">swisstopo</a>',
};

export const wmtsLayerTest = {
	id: "public-transportation-layer",
	type: "raster",
	paint: {
		// "raster-opacity": 1,
	},
};

// const tilePath =
// 	"wmts/epsg3857/best/" +
// 	"MODIS_Terra_CorrectedReflectance_TrueColor/default/" +
// 	"2018-06-01/GoogleMapsCompatible_Level9/{z}/{y}/{x}.jpg";

// https://gitc.earthdata.nasa.gov/legends/GEDI_MU_Color_Index_H.svg
const tilePath =
	"wmts/epsg3857/best/" +
	"GEDI_ISS_L4B_Aboveground_Biomass_Density_Mean_201904-202108/default/" +
	"2021-06-01/GoogleMapsCompatible_Level7/{z}/{y}/{x}.jpg";

// // https://gitc.earthdata.nasa.gov/legends/GEDI_RH100_Mean_Color_Index_H.svg
// const tilePath =
// 	"wmts/epsg3857/best/" +
// 	"GEDI_ISS_L3_Canopy_Height_Mean_RH100_201904-202201/default/" +
// 	"2022-01-01/GoogleMapsCompatible_Level7/{z}/{y}/{x}.jpg";

export const wmtsGibsSource = {
	id: "gibs-source",
	type: "raster",
	tiles: [
		"https://gibs-a.earthdata.nasa.gov/" + tilePath,
		"https://gibs-b.earthdata.nasa.gov/" + tilePath,
		"https://gibs-c.earthdata.nasa.gov/" + tilePath,
	],
	tileSize: 256,
};

export const wmtsGibsLayer = {
	id: "gibs-layer",
	type: "raster",
	paint: {
		"raster-opacity": 1,
	},
};

// https://gitc.earthdata.nasa.gov/legends/GEDI_RH100_Mean_Color_Index_H.svg
const tilePathVector =
	"wmts/epsg3857/best/" +
	"VIIRS_SNPP_Thermal_Anomalies_375m_All/default/" +
	"default/GoogleMapsCompatible_Level8/{z}/{y}/{x}.mvt";

export const wmtsGibsSourceVector = {
	id: "gibs-source-vector",
	type: "vector",
	maxzoom: 8,
	tiles: [
		"https://gibs-a.earthdata.nasa.gov/" + tilePathVector,
		"https://gibs-b.earthdata.nasa.gov/" + tilePathVector,
		"https://gibs-c.earthdata.nasa.gov/" + tilePathVector,
	],
	tileSize: 512,
};

export const wmtsGibsLayerVector = {
	id: "gibs-layer-vector",
	"source-layer": "gibs-source-vector",
	type: "fill",
};

export const wmtsSentinelSource = {
	id: "sentinel-source",
	type: "raster",
	tiles: [
		// "https://services.sentinel-hub.com/ogc/wms/106901f8-8ba8-451d-9332-7c7c6b657ef0",
		"https://services.sentinel-hub.com/ogc/wmts/106901f8-8ba8-451d-9332-7c7c6b657ef0?REQUEST=GetTile&LAYER=MOISTURE-INDEX",
	],
	tileSize: 512,
};

export const wmtsSentinelLayer = {
	id: "sentinal-layer",
	"source-layer": "MOISTURE-INDEX",
	type: "raster",
	paint: { "raster-resampling": "nearest" },
};
