import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSplash } from "../../contexts/splash";
import { useDataMap } from "../../contexts/datamap";

import { MapSearch, MapSearchInput, MapStatus } from "../../assets/styles/map";
import { convertLocaleSeparator } from "../../services/converters";

export default function Search(props) {
	//
	const { t } = useTranslation();

	const splashContext = useSplash();
	const dataMapContext = useDataMap();

	const [tokenNumber, setTokenNumber] = useState();

	///////////////////////////////////////////////////////////////
	///////////////////////////////////////////////////////////////

	return (
		<MapSearch>
			<>
				<form
					onSubmit={(e) => {
						dataMapContext.setOpenSearchToken(tokenNumber);
						e.preventDefault();
					}}>
					<MapSearchInput
						name="tokenNumber"
						placeholder="Token #"
						defaultValue={tokenNumber}
						onChange={(e) => {
							if (!isNaN(e.target.value)) setTokenNumber(e.target.value);
						}}
						// onChange={(event) => {
						// 	if (!/[0-9]/.test(event.key)) {
						// 		event.preventDefault();
						// 	}
						// }}
					/>
				</form>
			</>
		</MapSearch>
	);
}
