import styled from "styled-components";

import { COLORS } from "../colors";
import { DEVICES } from "../devices";

export const ContainerApp = styled.div`
  height: 100vh;
  font-family: sans-serif;
  text-align: center;
`;

export const Container = styled.div`
  width: 100%;

  transition: 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const Section = styled.section`
  justify-content: center;
  align-items: center;
  background: ${COLORS.gray_superlight};
  padding: 0.625em;

  @media ${DEVICES.laptopL} {
  }
`;
