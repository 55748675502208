// import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { COLORS } from "../../assets/colors";
import { CONFIGS } from "../../assets/configs";
import { IMAGES } from "../../assets/images";
//
import { SplashContainer, SplashScreen } from "../../assets/styles/splash";
import { useSplash } from "../../contexts/splash";

import Button from "../button";
import ForestWatch_Lettering_Gray from "../../assets/images/svg/ForestWatch_Lettering_Gray.svg";

export default function Splash(props) {
	//
	const { t } = useTranslation();

	const splashContext = useSplash();

	const [backgroundColor, setBackgroundColor] = useState(COLORS.sand);
	const [textColorTitle, setTextColorTitle] = useState(COLORS.gray_mid);
	const [textColorBody, setTextColorBody] = useState(COLORS.gray_mid);
	const [icon, setIcon] = useState(IMAGES.splash.loading);

	const fadeIn = function () {
		console.log("splash fadeIn");
	};

	const fadeOut = function () {
		console.log("splash fadeOut", splashContext.timer);
	};

	const handleCloseSplash = function () {
		console.log("handleCloseSplash");
		splashContext.closeSplash();
	};

	useEffect(() => {
		async function switchType(type) {
			switch (type) {
				case CONFIGS.splash.types.Loading:
					setBackgroundColor(COLORS.green_light);
					setTextColorTitle(COLORS.gray_dark);
					setTextColorBody(COLORS.gray_dark);
					setIcon(IMAGES.splash.loading);
					break;

				case CONFIGS.splash.types.Error:
					setBackgroundColor(COLORS.error);
					setTextColorTitle(COLORS.gray_mid);
					setTextColorBody(COLORS.gray_mid);
					setIcon(IMAGES.splash.error);
					break;

				case CONFIGS.splash.types.Success:
					setBackgroundColor(COLORS.success);
					setTextColorTitle(COLORS.white);
					setTextColorBody(COLORS.gray_mid);
					setIcon(IMAGES.splash.success);
					break;

				case CONFIGS.splash.types.Alert:
					setBackgroundColor(COLORS.alert);
					setTextColorTitle(COLORS.gray_dark);
					setTextColorBody(COLORS.gray_dark);
					setIcon(IMAGES.splash.alert);
					break;

				default:
					setBackgroundColor(COLORS.sand);
					setTextColorTitle(COLORS.gray_mid);
					setTextColorBody(COLORS.gray_mid);
					setIcon(IMAGES.splash.success);
					break;
			}
		}

		switchType(splashContext.type);

		if (splashContext.show === true) {
			fadeIn();
		} else {
			fadeOut();
		}
		// eslint-disable-next-line
	}, [splashContext.type]);

	return (
		<SplashScreen style={{ backgroundColor: backgroundColor }}>
			<div className="Logo">
				<img src={ForestWatch_Lettering_Gray} alt="Forest Watch" />
			</div>

			<SplashContainer>
				<div className="Image">
					<img
						src={icon}
						alt=""
						style={{ fill: textColorTitle, color: textColorTitle }}
					/>
				</div>

				<h1 style={{ color: textColorTitle }}>{splashContext.title}</h1>
				<p style={{ color: textColorBody }}>{splashContext?.body}</p>

				<div className="Spinner">
					<img
						src={IMAGES.icons.loading}
						alt={t("splash.loading.title")}
						className="rotate"
					/>
				</div>

				{splashContext.timer && (
					<Button
						onClick={handleCloseSplash}
						type={CONFIGS.button.types.Transparent}
						text={t("buttons.close")}></Button>
				)}
			</SplashContainer>
		</SplashScreen>
	);
}
