import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { COLORS } from "../assets/colors";
import { CONFIGS } from "../assets/configs";
import { IMAGES } from "../assets/images";
//
import { Container, Section } from "../assets/styles/elements";
//
import { useSplash } from "../contexts/splash";

export default function Public() {
  //
  const { t } = useTranslation();
  const splashContext = useSplash();

  useEffect(() => {
    console.log("useeffect");
    splashContext.closeSplash();
  }, []);

  async function handleSignIn() {
    console.log("handleSignIn");

    let splashConfig = {
      type: CONFIGS.splash.types.Alert,
      title: t("splash.error.title"),
      body: t("splash.error.body"),
      timer: 1500,
    };

    splashContext.openSplash(splashConfig);
  }

  return (
    <Section>
      <Container style={{ background: COLORS.green_light }}>
        <h1 className={"Roboto cFFF b05"}>Public</h1>
        <button onClick={handleSignIn}>Ativar lasers</button>
      </Container>
    </Section>
  );
}
