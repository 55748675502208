import iconLoadingTransparent from "../assets/images/svg/iconLoadingTransparent.svg";
import iconWaiting from "../assets/images/svg/iconLoadingClockGray.svg";
import iconLoading from "../assets/images/svg/iconLoading.svg";

export const IMAGES = {
	splash: {
		error: require("./images/png/iconError4x.png"),
		success: require("./images/png/iconSuccess4x.png"),
		alert: require("./images/png/iconAlert4x.png"),
		loading: iconWaiting,
	},

	icons: {
		loading: iconLoading,
		scanner: require("./images/png/iconScan4x.png"),
	},

	buttons: {
		menu: require("./images/png/iconMenu4x.png"),
		back: require("./images/png/iconBack4x.png"),
	},

	screens: {
		signin: {
			logo: require("./images/png/fwGlobeCenter.png"),
			love: require("./images/png/footerAnubz4x.png"),
		},

		dashboard: {
			logo: require("./images/png/fwGridWhite4x.png"),
		},
	},

	map: {
		header: {
			logo: require("./images/png/fwLetteringGray4x.png"),
		},
	},
};
