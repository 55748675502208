const sizes = {
  // mobileS: "320px",
  // mobileM: "375px",
  mobileL: "480px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",

  breakSmall: "576px",
  breakTablet: "768px",
  breakDesktop: "992px",
  breakLarge: "1200px",

  maxMobile: "425px",
  maxSmall: "576px",
  maxTablet: "1024px",
  maxDesktop: "1280px",
  maxLarge: "1281px",
};

export const DEVICES = {
  // mobileS: `(min-width: ${sizes.mobileS})`,
  // mobileM: `(min-width: ${sizes.mobileM})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,

  minSmall: `(min-width: ${sizes.maxSmall})`,
  minTablet: `(min-width: ${sizes.maxTablet})`,
  minDesktop: `(min-width: ${sizes.maxDesktop})`,
  minLarg: `(min-width: ${sizes.maxLarge})`,

  simpleSmall: `(min-width: ${sizes.breakSmall})`,
  simpleTablet: `(min-width: ${sizes.breakTablet})`,
  simpleDesktop: `(min-width: ${sizes.breakDesktop})`,
  simpleLarg: `(min-width: ${sizes.breakLarge})`,

  maxMobile: `(max-width: ${sizes.maxMobile})`,
  maxSmall: `(max-width: ${sizes.maxSmall})`,
  maxTablet: `(max-width: ${sizes.maxTablet})`,
  maxDesktop: `(max-width: ${sizes.maxDesktop})`,
  maxLarge: `(min-width: ${sizes.maxLarge})`,
};
