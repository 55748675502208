import React from "react";

export default function ErrorFallback({ error, resetErrorBoundary }) {
	return (
		<div role="alert">
			<p>Something went wrong:</p>
			<pre>{error.title}</pre>
			<p>{error.message}</p>
			<button onClick={resetErrorBoundary}>Try again</button>
		</div>
	);
}
