import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../errorfallback";

import { CONFIGS } from "../../assets/configs";
import { useDataMap } from "../../contexts/datamap";

import {
	MapMenuContainer,
	MapMenuButtonsList,
	MapMenuBackground,
	MapWidget,
	MapMenuHeader,
} from "../../assets/styles/map";

import iconBackWhite from "../../assets/images/svg/iconBackWhite.svg";

import Precipitation from "./widgets/preciptation";
import Soil from "./widgets/soil";
import Geocode from "./widgets/geocode";
import Stats from "./widgets/stats";
import Carbon from "./widgets/carbon";
import Satellite from "./widgets/satellite";
import Fire from "./widgets/fire";

import UVI from "./widgets/uvi";
import Weather from "./widgets/weather";
import WeatherAgro from "./widgets/weatherAgro";
import Biodiversity from "./widgets/biodiversity";
import Deforestation from "./widgets/deforestation";
import Inspections from "./widgets/inspections";

export default function Menu() {
	//
	const { t } = useTranslation();

	const [explode, setExplode] = useState(false);
	const [selectedMenu, setSelectedMenu] = useState();
	const [selectedMenuTitle, setSelectedMenuTitle] = useState();

	const dataMapContext = useDataMap();
	const globalPermissions = CONFIGS.apiPermissions;

	const apiPermissions = dataMapContext.apiPermissions.sort((a, b) =>
		a.title > b.title ? 1 : -1
	);

	// useEffect(() => {
	// 	// console.log(
	// 	// 	"$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ dataMapContext.inspectionsData CHANGED",
	// 	// 	dataMapContext.inspectionsData
	// 	// );
	// 	// if (dataMapContext.inspectionsData)
	// 	// 	console.log(
	// 	// 		"dataMapContext.inspectionsData",
	// 	// 		dataMapContext.inspectionsData
	// 	// 	);
	// }, [dataMapContext.inspectionsData]);

	function selectMenu(code) {
		setSelectedMenu(code);
		dataMapContext.setMenuActive(code);

		Object.entries(globalPermissions).forEach((permission) => {
			let perm = permission[1];
			if (perm.code === code) setSelectedMenuTitle(perm.title);
		});
	}

	return (
		<MapMenuContainer>
			{!selectedMenu ? (
				<>
					<MapMenuButtonsList>
						{apiPermissions &&
							apiPermissions.map((permission, index) => {
								//
								let hasNotifications = Object.entries(
									dataMapContext.notificationData
								).filter(([key, value]) => key === permission.code && value === true);

								return (
									<li key={index}>
										<span
											className="item"
											onClick={() => selectMenu(permission.code)}
											title={t("map.menu." + permission.code + ".description")}>
											{hasNotifications.length > 0 && (
												<span className="notification">&nbsp;</span>
											)}

											{t("map.menu." + permission.code + ".title")}
										</span>
									</li>
								);
							})}
					</MapMenuButtonsList>
				</>
			) : (
				<>
					<MapMenuBackground>
						<MapMenuHeader
							onClick={() => {
								selectMenu(null);
							}}>
							<div className="flex-items-sides">
								<img src={iconBackWhite} alt="Forest Watch" />
							</div>
							<div className="flex-items-center">{selectedMenuTitle}</div>
							<div className="flex-items-sides right">&nbsp;</div>
						</MapMenuHeader>

						{selectedMenu === CONFIGS.apiPermissions.apiWeather.code && (
							<>
								<MapWidget>
									<ErrorBoundary
										FallbackComponent={ErrorFallback}
										onError={(error, errorInfo) => console.log({ error, errorInfo })}
										onReset={() => setExplode(false)}>
										<Geocode />
									</ErrorBoundary>
								</MapWidget>
								<br />
								<MapWidget>
									<ErrorBoundary
										FallbackComponent={WeatherAgro}
										onError={(error, errorInfo) => console.log({ error, errorInfo })}
										onReset={() => setExplode(false)}>
										<Weather />
									</ErrorBoundary>
								</MapWidget>
							</>
						)}

						{selectedMenu === CONFIGS.apiPermissions.apiPrecipitation.code && (
							<MapWidget>
								<ErrorBoundary
									FallbackComponent={ErrorFallback}
									onError={(error, errorInfo) => console.log({ error, errorInfo })}
									onReset={() => setExplode(false)}>
									<Precipitation />
								</ErrorBoundary>
							</MapWidget>
						)}

						{selectedMenu === CONFIGS.apiPermissions.apiSoil.code && (
							<MapWidget>
								<ErrorBoundary
									FallbackComponent={ErrorFallback}
									onError={(error, errorInfo) => console.log({ error, errorInfo })}
									onReset={() => setExplode(false)}>
									<Soil />
								</ErrorBoundary>
							</MapWidget>
						)}

						{selectedMenu === CONFIGS.apiPermissions.apiFire.code && (
							<MapWidget>
								<ErrorBoundary
									FallbackComponent={ErrorFallback}
									onError={(error, errorInfo) => console.log({ error, errorInfo })}
									onReset={() => setExplode(false)}>
									<Fire />
								</ErrorBoundary>
							</MapWidget>
						)}

						{selectedMenu === CONFIGS.apiPermissions.apiSatellite.code && (
							<MapWidget>
								<ErrorBoundary
									FallbackComponent={ErrorFallback}
									onError={(error, errorInfo) => console.log({ error, errorInfo })}
									onReset={() => setExplode(false)}>
									<Satellite />
								</ErrorBoundary>
							</MapWidget>
						)}

						{selectedMenu === CONFIGS.apiPermissions.apiStats.code && (
							<MapWidget>
								<ErrorBoundary
									FallbackComponent={ErrorFallback}
									onError={(error, errorInfo) => console.log({ error, errorInfo })}
									onReset={() => setExplode(false)}>
									<Stats />
								</ErrorBoundary>
							</MapWidget>
						)}

						{selectedMenu === CONFIGS.apiPermissions.apiCarbon.code && (
							<MapWidget>
								<ErrorBoundary
									FallbackComponent={ErrorFallback}
									onError={(error, errorInfo) => console.log({ error, errorInfo })}
									onReset={() => setExplode(false)}>
									<Carbon />
								</ErrorBoundary>
							</MapWidget>
						)}

						{selectedMenu === CONFIGS.apiPermissions.apiBiodiversity.code && (
							<MapWidget>
								<ErrorBoundary
									FallbackComponent={ErrorFallback}
									onError={(error, errorInfo) => console.log({ error, errorInfo })}
									onReset={() => setExplode(false)}>
									<Biodiversity />
								</ErrorBoundary>
							</MapWidget>
						)}

						{selectedMenu === CONFIGS.apiPermissions.apiDesmatamento.code && (
							<MapWidget>
								<ErrorBoundary
									FallbackComponent={ErrorFallback}
									onError={(error, errorInfo) => console.log({ error, errorInfo })}
									onReset={() => setExplode(false)}>
									<Deforestation />
								</ErrorBoundary>
							</MapWidget>
						)}

						{selectedMenu === CONFIGS.apiPermissions.apiInspections.code && (
							<MapWidget>
								<ErrorBoundary
									FallbackComponent={ErrorFallback}
									onError={(error, errorInfo) => console.log({ error, errorInfo })}
									onReset={() => setExplode(false)}>
									<Inspections />
								</ErrorBoundary>
							</MapWidget>
						)}
					</MapMenuBackground>
				</>
			)}
		</MapMenuContainer>
	);
}
