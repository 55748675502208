import styled from "styled-components";

import { COLORS } from "../colors";
import { DEVICES } from "../devices";

const paddingInternal = "1em";
const margingExternal = "1em";
const headerMenuHeight = "3.75em";
const statusBoxWidth = "30em";

const searchHeight = "10em";
const searchWidth = "75vw";

const backgroundColor = COLORS.backgroundContainers;

const timeSeriesSides = "8em";

export const MapContainer = styled.div`
	position: absolute;

	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	/* padding: 0.5em; */
	/* border-radius: 0.75em; */
	margin: 1em;

	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	align-content: flex-end;

	& .Top {
		width: 100%;
		height: ${headerMenuHeight};
		margin-bottom: 1em;

		display: block;
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: auto;
		align-self: stretch;
		order: 0;
	}

	& .Bottom {
		width: 20em;
		height: auto;
		background: yellow;

		display: block;
		flex-grow: 1;
		flex-shrink: 0;
		flex-basis: auto;
		align-self: auto;
		order: 0;
	}

	& .Bottom div:last-child {
		margin-left: auto;
	}
`;

export const MapHeader = styled.div`
	height: ${headerMenuHeight};

	position: absolute;
	margin: 1em;

	top: 0;
	left: 0;
	right: 0;

	text-align: left;
	border-radius: 0.5em;
	outline: none;

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: normal;
	align-items: normal;
	align-content: normal;

	& img {
		height: calc(${headerMenuHeight} - 0.5em);
		width: auto;
	}

	& .flex-items-sides {
		display: block;
		flex-grow: 0;
		flex-shrink: 1;
		/* flex-basis: auto; */
		align-self: auto;
		flex-basis: 20vw;
		order: 0;
		/* width: 20vw; */
	}

	& .flex-items-sides.right {
		text-align: right;
	}

	& .flex-items-center {
		display: block;
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: auto;
		align-self: auto;
		order: 0;
		/* background: gray; */
		width: 60vw;
	}

	@media ${DEVICES.maxSmall} {
		& .flex-items-center {
			display: none;
		}

		& .flex-items-sides {
			width: 50vw;
			flex-basis: auto;
			/* background: purple; */
		}
	}

	& button {
		background: transparent;
		border: none;
		height: ${headerMenuHeight};
		margin-left: 0.25em;
		cursor: pointer;
	}

	& button img {
		height: 100%;
		width: auto;
	}
`;

export const MapFooter = styled(MapHeader)`
	top: auto;
	bottom: 0;
	/* background: #fc0; */
`;

export const MapTime = styled.div`
	height: ${headerMenuHeight};
	width: 100%;

	color: ${COLORS.gray_superlight};
	background: ${backgroundColor};
	backdrop-filter: blur(8px);

	border-radius: ${headerMenuHeight};
	padding-top: 1em;

	& ol {
		width: 100%;
		margin: 0 auto;
		transition: all 1s;
		display: flex;
		justify-content: space-between;
	}

	& ol li {
		position: relative;
		padding: 0 5px;
		top: 1.25em;

		list-style: none;
		text-align: center;
		font-size: 0.85em !important;

		flex-grow: 1;
		flex-basis: 0;
		cursor: pointer;
	}

	& ol li div {
		position: absolute;
		left: calc(50% - 1.525em);
		/* margin: ; */
		top: -1.5em;
		white-space: normal;
	}

	& ol li:before {
		content: "";
		display: block;
		width: 10px;
		height: 10px;
		margin: 0 auto 5px auto;

		border-radius: 50%;
		background: ${COLORS.gray_light};
	}

	& ol li div.selected {
		font-weight: 500;
	}

	& ol li.selected:before {
		background: ${COLORS.error};
		color: ${COLORS.gray_dark};

		width: 16px;
		height: 16px;
		margin: -3px auto 4px auto;
	}

	& ol li:not(:last-child)::after {
		content: "";
		width: calc(100% - 16px);
		height: 2px;
		display: block;
		background: ${COLORS.gray_light};
		margin: 0;
		position: absolute;
		top: 4px;
		left: calc(50% + 8px);
	}

	/* @media ${DEVICES.maxTablet} {
    & ol li:nth-child(-n + 7) {
      display: none;
    }
  } */

	& .iconDatePicker {
		width: 2.25em;
		height: 2.25em;

		color: ${COLORS.gray_light};
		margin-top: -0.25em;

		cursor: pointer;
	}

	& .noImages {
		width: 100%;
		text-align: center;
		/* padding-top: 0.25em; */
	}

	& .timeSeriesContainer {
		/* background-color: aliceblue; */

		padding: 0 1em;
		display: flex;
		justify-content: normal;

		flex-direction: row;
		flex-wrap: nowrap;

		align-items: normal;
		align-content: normal;
	}

	& .timeSeriesLeft {
		padding-left: 0.75em;

		display: block;
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: auto;
		align-self: auto;
		order: 0;

		width: ${timeSeriesSides};
	}

	& .timeSeriesLeft .datePicker {
		float: left;
	}

	& .timeSeriesLeft .timeSelected {
		float: left;
		padding-left: 1em;
		/* background-color: bisque; */
	}

	& .timeSeriesLeft .timeSelected p {
		font-size: 0.75em;
		margin-bottom: 0.125em;
	}

	& .timeSeriesRight {
		padding-top: 1em;
		padding-right: 0.75em;

		display: block;
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: auto;
		align-self: auto;
		order: 0;

		text-align: right;
		width: ${timeSeriesSides};

		@media ${DEVICES.maxTablet} {
			display: none;
		}
	}

	& .timeSeriesCenter {
		display: block;
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: auto;
		align-self: auto;
		order: 0;
	}

	@media ${DEVICES.maxMobile} {
		display: none;
	}
`;

export const MapMenuContainer = styled.div`
	position: absolute;

	top: calc(${headerMenuHeight} + calc(2 * ${paddingInternal}));
	right: ${paddingInternal};
	width: 20em;

	overflow: hidden;
	/* border-radius: 1em; */
	padding: 0.5em 0;

	max-height: calc(
		100% - calc(calc(${headerMenuHeight} + calc(2 * ${paddingInternal})))
	);

	/* background-color: aqua; */
	/* bottom: 0; */

	text-align: left;
	outline: none;

	@media ${DEVICES.maxTablet} {
		width: calc(100% - calc(2 * ${paddingInternal}));
		/* height: calc(
      100vh - calc(${headerMenuHeight} + calc(2 * ${paddingInternal}))
    ); */
		left: ${paddingInternal};
		right: ${paddingInternal};
	}
`;

export const MapMenuBackground = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 1em;
	padding: ${paddingInternal};

	background-color: ${backgroundColor};
	backdrop-filter: blur(8px);
	color: ${COLORS.gray_light};

	& img {
		height: 2.5em;
		width: auto;
	}
`;

export const MapMenuButtonsList = styled.ul`
	display: flex;
	padding: ${paddingInternal};
	text-align: right;

	flex-direction: column;
	list-style-type: none;

	margin: 0;
	padding: 0;

	& li {
		list-style: none;
		margin-bottom: 1em;
		padding: calc(${paddingInternal} / 2.5) 0;
	}

	& li span.item {
		padding: calc(${paddingInternal} / 2);
		padding-left: 2em;
		padding-right: 0.75em;
		border-radius: 1em;
		cursor: pointer;

		background-color: ${backgroundColor};
		backdrop-filter: blur(8px);

		color: ${COLORS.gray_superlight};
		font-size: 1.25em;
		transition: all 150ms ease-out;
	}

	& li span.item:hover {
		background-color: ${COLORS.white};
		color: ${COLORS.gray_dark};
	}

	& li span.notification {
		width: 1em !important;
		height: 1em !important;

		background: ${COLORS.error};

		border-radius: 4em;
		margin-right: 0.75em;
	}
`;

export const MapWidget = styled.div`
	width: 100%;
	padding: ${paddingInternal};
	border-radius: 0.75em;

	background: ${COLORS.white};
	color: ${COLORS.gray_mid};

	position: relative;
	max-height: 100%;
	overflow: scroll;

	& h1 {
		font-weight: 600 !important;
		font-size: 1.5em;
		letter-spacing: -0.025em;
		margin-bottom: 0.5em;
	}

	& h2 {
		font-weight: 300 !important;
		font-size: 1.25em;
	}

	& ul {
		margin: 1em 0;
	}

	& ul li {
		list-style: none;
		margin-bottom: 0.75em;
	}

	& strong {
		font-weight: 500 !important;
	}
`;

export const MapWidgetLoading = styled.div`
	width: 100%;
	padding: ${paddingInternal};
	border-radius: 0.65em;

	text-align: center;
	background: ${COLORS.gray_superlight};
	color: ${COLORS.gray_mid};
`;

export const MapMenuHeader = styled.div`
	padding-bottom: calc(${paddingInternal} / 2);

	color: ${COLORS.white};
	font-size: 1.25em;

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	justify-content: normal;
	align-items: normal;
	align-content: normal;

	& .flex-items-sides {
		display: block;
		flex-grow: 0;
		flex-shrink: 1;
		/* flex-basis: auto; */
		align-self: auto;
		flex-basis: 20vw;
		order: 0;
		width: 1em;
	}

	& .flex-items-sides img {
		width: 0.9em;
		height: auto;
		cursor: pointer;
	}

	& .flex-items-sides.right {
		text-align: right;
	}

	& .flex-items-center {
		text-align: center;

		display: block;
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: auto;
		align-self: auto;
		order: 0;
		/* background: gray; */
		width: 60vw;
	}
`;

export const MapAdMini = styled.div`
	position: fixed;

	top: calc(${headerMenuHeight} + 1em);
	left: 0;

	/* height: calc(${headerMenuHeight} * 2); */
	width: calc(${headerMenuHeight} * 3);

	margin: ${margingExternal};
	padding: 0 !important;

	text-align: left;
	border-radius: 0.5em;

	background: ${COLORS.transparent} !important;

	@media ${DEVICES.maxSmall} {
		display: none;
	}

	& img {
		width: 100%;
		height: auto;

		object-fit: cover;
		border-radius: 0.5em;

		margin: 0 !important;
		padding: 0 !important;

		cursor: pointer;
	}
`;

export const MapStatus = styled.div`
	position: fixed;

	bottom: calc(${margingExternal});
	left: calc(50% - (${statusBoxWidth} / 2));

	/* height: calc(${headerMenuHeight}); */
	width: ${statusBoxWidth};

	padding: ${paddingInternal};
	color: ${COLORS.gray_superlight};

	text-align: center;
	border-radius: ${headerMenuHeight};

	background: ${COLORS.backgroundContainers} !important;

	@media ${DEVICES.maxSmall} {
		/* display: none; */
		width: auto;
		left: ${margingExternal};
		right: ${margingExternal};
		bottom: ${margingExternal};
	}

	& h3 {
		color: ${COLORS.gray_midlight};
		font-size: 0.75em;
	}
`;

export const MapWidgetSatellite = styled.div`
	@media ${DEVICES.maxSmall} {
	}

	& .legendContainer {
		display: flex;
		flex-wrap: wrap;
		font-size: 10;
	}

	& .legendItem {
		padding: 0.25em;
	}

	& .legendItemBullet {
		width: 1.5em;
		height: 1em;
		border-radius: 2em;
	}

	& h3 {
		color: ${COLORS.gray_midlight};
		font-size: 0.75em;
	}
`;

export const MapSearch = styled.div`
	position: fixed;

	top: calc(50% - (${searchHeight} / 2));
	left: calc(50% - (${searchWidth} / 2));

	height: calc(${searchHeight});
	width: calc(${searchWidth});

	/* border-radius: 25em; */
	/* border: 0.5em solid ${COLORS.gray_mid}; */

	/* background: ${COLORS.gray_superlight} !important; */
	/* color: ${COLORS.gray_superlight}; */

	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;

	@media ${DEVICES.maxSmall} {
		/* display: none; */
		width: auto;
		left: ${margingExternal};
		right: ${margingExternal};
		bottom: ${margingExternal};
	}
`;

export const MapSearchInput = styled.input`
	/* height: calc(${searchHeight}); */
	width: calc(${searchWidth});
	background-color: ${COLORS.gray_superlight};
	color: ${COLORS.gray_mid};

	border-radius: 25em;
	border: 0 solid ${COLORS.backgroundContainers};
	padding: 1em 1em;

	font-family: "Roboto", sans-serif;
	font-size: 2.5em;
	text-align: center;

	box-shadow: 0px 0px 175px 40px rgba(9, 9, 9, 0.95);
`;
