import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { load } from "@loaders.gl/core";
import { JSONLoader } from "@loaders.gl/json";
import { useErrorHandler } from "react-error-boundary";

import { CONFIGS } from "../../../assets/configs";
import { useDataMap } from "../../../contexts/datamap";

export default function Geocode() {
  const handleError = useErrorHandler();

  const { t } = useTranslation();
  const dataMapContext = useDataMap();
  const [dataGeocode, setDataGeocode] = useState();

  /////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (dataMapContext.dataLotProperties) {
      callApiGeocode();
    }
    // eslint-disable-next-line
  }, [dataMapContext.dataLotProperties, dataMapContext.timeSeries]);

  /////////////////////////////////////////////////////////////////

  async function callApiGeocode() {
    //

    let fetchURL =
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      dataMapContext.dataLotProperties.centerOfMass[0] +
      "," +
      dataMapContext.dataLotProperties.centerOfMass[1] +
      ".json?access_token=" +
      CONFIGS.mapboxToken;

    await load(fetchURL, JSONLoader)
      .then((resp) => {
        let geoPlace = resp.features.filter(function (el) {
          return el.id.indexOf("place") >= 0;
        });
        let locationName = geoPlace[0].place_name;
        setDataGeocode(locationName);
      })
      .catch((err) => {
        handleError(err);
      });
  }

  return (
    <>
      {dataGeocode && (
        <>
          <h1>{dataGeocode}</h1>
        </>
      )}
    </>
  );
}
