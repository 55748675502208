import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { load } from '@loaders.gl/core';
import { JSONLoader } from '@loaders.gl/json';
import { useErrorHandler } from 'react-error-boundary';

import { CONFIGS } from '../../../assets/configs';
import {
  convertUnixToDate,
  convertLocaleSeparator,
  convertKelvinToCelsius,
} from '../../../services/converters';

import { useDataMap } from '../../../contexts/datamap';
import Loading from './loading';

export default function Soil() {
  const handleError = useErrorHandler();

  const { t } = useTranslation();
  const dataMapContext = useDataMap();
  const [dataSoil, setDataSoil] = useState();

  /////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (dataMapContext.dataLotProperties) {
      callApiSoil();
    }
    // eslint-disable-next-line
  }, [dataMapContext.dataLotProperties, dataMapContext.timeSeries]);

  /////////////////////////////////////////////////////////////////

  async function callApiSoil() {
    //
    let fetchURL =
      'https://api.agromonitoring.com/agro/1.0/soil?polyid=' +
      dataMapContext.dataLotProperties.polygonAgroAPIToken +
      '&appid=' +
      CONFIGS.agroAPIToken;

    await load(fetchURL, JSONLoader)
      .then((resp) => {
        console.log('SOIL ', resp);
        setDataSoil(resp);
      })
      .catch((err) => {
        handleError(err);
      });
  }

  return (
    <>
      {dataSoil ? (
        <>
          <p>Solo, Umidade: {convertLocaleSeparator(dataSoil?.moisture)} /m3</p>
          <p>Solo, Temp. solo: {convertKelvinToCelsius(dataSoil?.t0)}</p>
          <p>Solo, Temp. 10cm solo: {convertKelvinToCelsius(dataSoil?.t10)}</p>
          <p>DT: {convertUnixToDate(dataSoil?.dt)}</p>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
