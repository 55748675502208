import React, { createContext, useContext, useState, useEffect } from "react";
import { CONFIGS } from "../assets/configs";

const SplashContext = createContext();

const SplashProvider = function ({ children }) {
	//
	const [show, setShow] = useState(false);

	const [type, setType] = useState();
	const [title, setTitle] = useState();
	const [body, setBody] = useState();
	const [timer, setTimer] = useState();

	const [language, setLanguage] = useState("br");

	useEffect(() => {
		console.log("language changed", language);
	}, [language]);

	async function openSplash(config) {
		console.log("CONTEXT OPEN SPLASH");
		//
		setType(config?.type);
		setTitle(config?.title);
		setBody(config?.body);
		setShow(true);

		console.log("CONTEXT OPEN SPLASH", config);

		if (config?.timer) {
			console.log("config?.timer", config?.timer);

			let timerClose =
				config?.timer === true ? CONFIGS.splash.timer.timeout : config?.timer;
			setTimer(timerClose);

			await new Promise(() =>
				setTimeout(function () {
					setShow(false);
				}, timerClose)
			);
		}
	}

	async function closeSplash() {
		setShow(false);
	}

	return (
		<SplashContext.Provider
			value={{
				show,
				type,
				title,
				body,
				timer,
				language,
				openSplash,
				closeSplash,
				setLanguage,
			}}>
			{children}
		</SplashContext.Provider>
	);
};

function useSplash() {
	const context = useContext(SplashContext);
	if (!context) {
		throw new Error("useSplash must be used within an SplashProvider");
	}
	return context;
}

export { SplashProvider, useSplash };
