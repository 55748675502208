import React, { useEffect, useState, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

import { useSplash } from "../../contexts/splash";
import { useDataMap } from "../../contexts/datamap";

import { COLORS } from "../../assets/colors";
import { CONFIGS } from "../../assets/configs";

import { MapFooter, MapTime } from "../../assets/styles/map";
import { convertUnixToDate } from "../../services/converters";

import Status from "./status";

// registerLocale("ptBR", ptBR);

export default function Footer(props) {
	//
	const { t } = useTranslation();

	const splashContext = useSplash();
	const dataMapContext = useDataMap();

	return (
		<MapFooter>
			<div className="flex-items-sides">{/* <h1>oi</h1> */}</div>
			<div className="flex-items-center">
				<Status />
			</div>
			<div className="flex-items-sides right">{/* <h1>oi</h1> */}</div>
		</MapFooter>
	);
}
