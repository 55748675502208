import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
// import { useNavigate } from "react-router-dom";

import api from "../services/api";
import * as auth from "../services/auth";

const authContext = createContext();

const AuthProvider = function ({ children }) {
  //
  const [user, setUser] = useState(null);
  // const navigate = useNavigate();

  useEffect(() => {
    //
    console.log("loadStorageData on useEffect at AuthProvider");
    async function loadStorageData() {
      const storagedUser = window.localStorage.getItem("@ForestWatch:user");

      if (storagedUser) {
        setUser(JSON.parse(storagedUser));
        api.defaults.headers.Authorization = `Baerer ${storagedUser.user_token}`;
      }
    }

    loadStorageData();
  });

  async function signIn(email, password) {
    //
    const response = await auth.signIn(email, password);
    setUser(response.user);

    api.defaults.headers.Authorization = `Baerer ${response.user.user_token}`;
    console.log("response " + response);

    await window.localStorage.setItem(
      "@ForestWatch:user",
      JSON.stringify(response.user)
    );
  }

  async function signOut() {
    await window.localStorage.clear();
    setUser(null);
  }

  const value = useMemo(
    () => ({
      signed: !!user,
      user,
      signIn,
      signOut,
    }),
    [user]
  );

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};

function useAuth() {
  const context = useContext(authContext);

  if (!context) {
    throw new Error("useAuth must be used within an authProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
