import React, { useEffect, useState } from "react";
import { forEach, load } from "@loaders.gl/core";
import { JSONLoader } from "@loaders.gl/json";

import { useTranslation } from "react-i18next";

import { convertUnixToDate } from "../../../services/converters";
import { useDataMap } from "../../../contexts/datamap";

import { COLORS } from "../../../assets/colors";
import { CONFIGS } from "../../../assets/configs";
import {
	convertLongToShort,
	convertLocaleSeparator,
} from "../../../services/converters";

import Loading from "./loading";
import Slider from "../../slider";
import { MapWidgetSatellite } from "../../../assets/styles/map";

export default function Satellite() {
	const { t } = useTranslation();
	const dataMapContext = useDataMap();

	const [dataSatellite, setDataSatellite] = useState();
	const [dataIndexes, setDataIndexes] = useState(null);

	const [dataIndexSelected, setDataIndexSelected] = useState(null);
	const [dataIndexSelectedLegend, setDataIndexSelectedLegend] = useState(null);

	const [dataStatsIndex, setDataStatsIndex] = useState(null);

	/////////////////////////////////////////////////////////////////

	const satelliteLegends = {
		ndvi: {
			title: "Normalized Difference Vegetation Index",
			description: "Metric for quantifying the health and density of vegetation",
			legend: {
				nobio: {
					title: "Não vegetal",
					value: -1,
					color: "#c8c8c8",
				},
				morta: {
					title: "Morta",
					value: 0,
					color: "#CA3939",
				},
				doente: {
					title: "Doente",
					value: 0.33,
					color: "#F7CA03",
				},
				moderado: {
					title: "Moderado",
					value: 0.66,
					color: "#95CC52",
				},
				saudavel: {
					title: "Saudável",
					value: 1,
					color: "#4A8230",
				},
			},
		},
		evi2: {
			title: "Enhanced Vegetation Index 2",
			description: "2-band Enhanced Vegetation Index",
			legend: {
				nohas: {
					title: "0",
					value: 0,
					color: "#eff1d3",
				},
				has: {
					title: "0.1",
					value: 0.1,
					color: "#ebfbb0",
				},
				doente: {
					title: "0.3",
					value: 0.3,
					color: "#8ddf89",
				},
				moderado: {
					title: "0.6",
					value: 0.6,
					color: "#017239",
				},
				saudavel: {
					title: "1",
					value: 1,
					color: "#105236",
				},
			},
		},
		evi: {
			title: "Enhanced Vegetation Index",
			description:
				"Enhance the vegetation signal with improved sensitivity in high biomass regions and improved vegetation monitoring through a de-coupling of the canopy background signal and a reduction in atmosphere influences",
			legend: {
				nohas: {
					title: "0",
					value: 0,
					color: "#eff1d3",
				},
				has: {
					title: "0.1",
					value: 0.1,
					color: "#ebfbb0",
				},
				doente: {
					title: "0.3",
					value: 0.3,
					color: "#8ddf89",
				},
				moderado: {
					title: "0.6",
					value: 0.6,
					color: "#017239",
				},
				saudavel: {
					title: "1",
					value: 1,
					color: "#105236",
				},
			},
		},
		dswi: {
			title: "Disease Water Stress Index",
			description: "Detect water-stressed crops at a canopy level",
			legend: {},
		},
		ndwi: {
			title: "Normalized Difference Water Index",
			description:
				"Highlight open water features in a satellite image, allowing a water body to “stand out” against the soil and vegetation",
			legend: {},
		},
		nri: {
			title: "Nitrogen Reflectance Index",
			description:
				"Estimate plant nitrogen status and illustrate the spatial variability of plant nitrogen status in the field",
			legend: {},
		},
	};

	/////////////////////////////////////////////////////////////////

	const cloudCoverageRange = (start, stop, step) =>
		Array.from(
			{ length: (stop - start) / step + 1 },
			(value, index) => start + index * step
		);

	/////////////////////////////////////////////////////////////////

	useEffect(() => {
		console.log(
			"dataMapContext.timeSeriesSelected",
			dataMapContext.timeSeriesSelected
		);
		if (dataMapContext.timeSeriesSelected) {
			setDataSatellite(dataMapContext.timeSeriesSelected);
		} else {
			setDataSatellite(null);
		}
		// eslint-disable-next-line
	}, [dataMapContext.timeSeriesSelected]);

	useEffect(() => {
		if (dataSatellite) {
			let indexesLabels = dataSatellite.tile;
			indexesLabels = Object.keys(indexesLabels);
			setDataIndexes(indexesLabels);
		}
		// eslint-disable-next-line
	}, [dataSatellite]);

	useEffect(() => {
		console.log(dataIndexSelected);
		setDataIndexSelectedLegend(null);

		Object.entries(satelliteLegends).forEach((legend) => {
			if (dataIndexSelected === legend[0]) {
				console.log(legend);
				setDataIndexSelectedLegend(legend[1]);
			}
		});

		fetchStats();
		// eslint-disable-next-line
	}, [dataIndexSelected]);

	/////////////////////////////////////////////////////////////////

	function handleIndexChange(event) {
		let rasterLayer = event.target.value;
		dataMapContext.setRasterLayerIndex(rasterLayer);
		setDataIndexSelected(rasterLayer);
	}

	function handleCloudCoverageChange(event) {
		let satelliteCoverage = event.target.value;
		dataMapContext.setSatelliteCloudCoverage(satelliteCoverage);
		console.log("satelliteCoverage %", satelliteCoverage);
	}

	async function fetchStats() {
		if (dataIndexSelected) {
			let fetchURL = null;

			Object.entries(dataSatellite.stats).forEach((stats) => {
				if (dataIndexSelected === stats[0]) {
					fetchURL = stats[1];
				}
			});

			if (fetchURL) {
				fetchURL = fetchURL.replace("http://", "https://");
				// console.log("fetchURL %", fetchURL);

				const response = await fetch(fetchURL).catch((err) => {
					console.log("err", err);
				});

				const data = await response.json();
				// console.log("resp", data);

				setDataStatsIndex(data);
			}
		}
	}

	/////////////////////////////////////////////////////////////////

	return (
		<>
			<h1>Cloud Coverage:</h1>
			<select
				onChange={handleCloudCoverageChange}
				defaultValue={dataMapContext.satelliteCloudCoverage}>
				{cloudCoverageRange(0, 100, 5).map((index) => (
					<option key={index} value={index}>
						{index} %
					</option>
				))}
			</select>
			<br />
			<br />

			{dataSatellite && dataIndexes ? (
				<>
					<h1>Image & Sensors</h1>
					<select
						onChange={handleIndexChange}
						value={dataMapContext.rasterLayerIndex}>
						<option value={""}>Selecione uma camada</option>

						{dataIndexes?.map((index) => (
							<option key={index} value={index}>
								{index}
							</option>
						))}
					</select>

					{dataIndexSelectedLegend && (
						<>
							<br />
							<br />
							<h1>{dataIndexSelectedLegend.title}</h1>
							<h2>{dataIndexSelectedLegend.description}</h2>
						</>
					)}

					<br />
					{dataIndexSelected && (
						<>
							<h1>Sobreposição</h1>
							<Slider />
						</>
					)}

					<br />
					<h1>Satellite Data</h1>
					<p>Name: {dataSatellite.type}</p>
					<p>Valid Data Coverage DC: {dataSatellite?.dc}%</p>
					<p>Cloud Coverag CL: {dataSatellite?.cl}%</p>
					<p>Sun Elevation: {dataSatellite?.sun.elevation}</p>
					<p>Sun Azimuth: {dataSatellite?.sun.azimuth}</p>
					<p>DT: {convertUnixToDate(dataSatellite?.dt)}</p>

					{dataStatsIndex && (
						<>
							<br />
							<h1>Sensor Index KPIs</h1>

							<p>Total Pixels: {convertLocaleSeparator(dataStatsIndex?.num)}</p>
							<br />
							<p>Max: {convertLongToShort(dataStatsIndex?.max, 8)}</p>
							<p>Min: {convertLongToShort(dataStatsIndex?.min, 8)}</p>
							<p>Mean: {convertLongToShort(dataStatsIndex?.mean, 8)}</p>
							<p>Median: {convertLongToShort(dataStatsIndex?.median, 8)}</p>

							<br />
							<p>1st Quartile: {convertLongToShort(dataStatsIndex?.p25, 8)}</p>
							<p>3dr Quartile: {convertLongToShort(dataStatsIndex?.p75, 8)}</p>
							<p>Std Deviation: {convertLongToShort(dataStatsIndex?.std, 8)}</p>
						</>
					)}

					<br />
					{}
					<MapWidgetSatellite>
						<div className="legendContainer">
							{dataIndexSelectedLegend &&
								Object.values(dataIndexSelectedLegend.legend).map((item, index) => {
									return (
										<div className="legendItem" key={index}>
											<div
												className="legendItemBullet"
												style={{ backgroundColor: item["color"] }}></div>
											{item["title"]}
										</div>
									);
								})}
						</div>
					</MapWidgetSatellite>
				</>
			) : (
				<Loading
					title={!dataSatellite ? "Nenhuma imagem com esses ajustes." : ""}
				/>
			)}
		</>
	);
}
