import React, { useEffect, useState } from "react";

import { COLORS } from "../../assets/colors";
import { CONFIGS } from "../../assets/configs";
import { HTMLButton } from "../../assets/styles/html";

export default function Button(props) {
  //
  const { type, text } = props;

  const [backgroundColor, setBackgroundColor] = useState(COLORS.sand);
  const [textColor, setTextColor] = useState(COLORS.gray_mid);
  const [typeButton, setTypeButton] = useState(type);

  useEffect(
    (props) => {
      console.log("props", props);
      async function switchType(type) {
        setTypeButton(type);
        console.log("type", type === CONFIGS.button.types.Transparent);

        switch (type) {
          case CONFIGS.button.types.Negative:
            setBackgroundColor(COLORS.transparent);
            setTextColor(COLORS.white);
            break;

          case CONFIGS.button.types.Transparent:
            setBackgroundColor(COLORS.transparent);
            setTextColor(COLORS.gray_mid);
            break;

          case CONFIGS.button.types.Light:
            setBackgroundColor(COLORS.transparent);
            setTextColor(COLORS.gray_mid);
            break;

          case CONFIGS.button.types.Dark:
            setBackgroundColor(COLORS.gray_mid);
            setTextColor(COLORS.white);
            break;

          default:
            setBackgroundColor(COLORS.transparent);
            setTextColor(COLORS.gray_mid);
            break;
        }
      }

      switchType(type);
    },
    [typeButton]
  );

  return (
    <HTMLButton
      className={[
        typeButton === CONFIGS.button.types.Light ? "bordered" : "borderless",
      ]}
      onClick={props.onClick}
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
      }}
    >
      {text}
    </HTMLButton>
  );
}

export { Button };
